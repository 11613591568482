import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class DeviceService {
  private statusManager_: BehaviorSubject<any> = new BehaviorSubject({});
  statusChange: Observable<any>;
  private messageManager_: BehaviorSubject<any> = new BehaviorSubject({});
  messageChange: Observable<any>;

  constructor() {
    this.statusChange = this.statusManager_.asObservable();      
    this.messageChange = this.messageManager_.asObservable();
  }

  setStatus(status: any) {
    this.statusManager_.next(status);
  }

  setMessage(message: any) {
    this.messageManager_.next(message);
  }
}

