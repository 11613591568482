import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HexComponent } from './hex.component';
import { SharedModule } from '../shared/shared.module';

const branchRoutes: Routes = [
    {
    path: 'hex',
    component: HexComponent
    }
];

@NgModule({
  declarations: [
    HexComponent     
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forChild(branchRoutes)
  ],
  bootstrap: [
    HexComponent
  ],
  exports: [
    HexComponent  
  ],
})

export class HexModule {}