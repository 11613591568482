import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class Cache {
  private userManager_: BehaviorSubject<any> = new BehaviorSubject({});
  userChange: Observable<any>;
  private companyListManager_: BehaviorSubject<any> = new BehaviorSubject(null);
  companyListChange: Observable<any>;
  private branchListManager_: BehaviorSubject<any> = new BehaviorSubject(null);
  branchListChange: Observable<any>;
  private branchManager_: BehaviorSubject<any> = new BehaviorSubject(null);
  branchChange: Observable<any>;
  private taskManager_: BehaviorSubject<String> = new BehaviorSubject('');
  taskChange: Observable<String>;
  private imageType2Manager_: BehaviorSubject<any> = new BehaviorSubject({});
  imageType2Change: Observable<any>;
  private imageType4Manager_: BehaviorSubject<any> = new BehaviorSubject({});
  imageType4Change: Observable<any>;
  private imageType5Manager_: BehaviorSubject<any> = new BehaviorSubject({});
  imageType5Change: Observable<any>;
  private imageType6Manager_: BehaviorSubject<any> = new BehaviorSubject({});
  imageType6Change: Observable<any>;
  private imageType16Manager_: BehaviorSubject<any> = new BehaviorSubject({});
  imageType16Change: Observable<any>;

  constructor(
    private cookie: CookieService,
    private router: Router) {
    this.userChange = this.userManager_.asObservable();      
    this.imageType2Change = this.imageType2Manager_.asObservable();
    this.imageType4Change = this.imageType4Manager_.asObservable();
    this.imageType5Change = this.imageType5Manager_.asObservable();
    this.imageType6Change = this.imageType6Manager_.asObservable();
    this.imageType16Change = this.imageType16Manager_.asObservable();
    this.companyListChange = this.companyListManager_.asObservable();
    this.branchListChange = this.branchListManager_.asObservable();
    this.branchChange = this.branchManager_.asObservable();
    this.taskChange = this.taskManager_.asObservable();
    //this.webPageChange = this.pageManager_.asObservable();    
    var user = JSON.parse(localStorage.getItem('user'));
    this.userManager_.next(user);  
    var image = JSON.parse(localStorage.getItem('imageType2'));
    this.imageType2Manager_.next(image);  
    image = JSON.parse(localStorage.getItem('imageType4'));
    this.imageType4Manager_.next(image); 
    image = JSON.parse(localStorage.getItem('imageType5'));
    this.imageType5Manager_.next(image); 
    image = JSON.parse(localStorage.getItem('imageType6'));
    this.imageType6Manager_.next(image);  
    image = JSON.parse(localStorage.getItem('imageType16'));
    this.imageType16Manager_.next(image);  
  }

  setUser(user: any) {
    this.userManager_.next(user);
    localStorage.setItem('user', JSON.stringify(user));  
  }

  setImageSet(type: any, image: any) {
    if(type == 2) {
      this.imageType2Manager_.next(image);
      localStorage.setItem('imageType2', JSON.stringify(image)); 
    } else if(type == 3) {
      this.imageType4Manager_.next(image);
      localStorage.setItem('imageType4', JSON.stringify(image)); 
    } else if(type == 5) {
      this.imageType5Manager_.next(image);
      localStorage.setItem('imageType5', JSON.stringify(image)); 
    } else if(type == 6) {
      this.imageType6Manager_.next(image);
      localStorage.setItem('imageType6', JSON.stringify(image)); 
    } else if(type == 16) {
      this.imageType16Manager_.next(image);
      localStorage.setItem('imageType16', JSON.stringify(image));
    } 
  }

  setCompanyList(company: any) {
    this.companyListManager_.next(company);
  }

  setBranchList(branch: any) {
    this.branchListManager_.next(branch);
  }

  setBranch(branch: any) {
    this.branchManager_.next(branch);
  }

  task(cmd): void {
    this.taskManager_.next(cmd);
  }

  logout(): void {
    //this.setWebPage_(WebPage.Logout);
    this.cookie.delete('token');
    localStorage.removeItem('user');
    localStorage.removeItem('imageType2');
    localStorage.removeItem('imageType4');
    localStorage.removeItem('imageType5');
    localStorage.removeItem('imageType6');
    localStorage.removeItem('imageType16');
    this.router.navigate(['login']);    
  }
}
