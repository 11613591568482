import { Component, ViewChildren, ViewChild, QueryList, HostListener, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { saveAs } from 'file-saver';
import { Cache } from '../shared/cache.service';
import { SchedulingComponent } from './scheduling.component';
import { SchedulingGraphComponent } from './scheduling.graph.component';

@Component({
  selector: 'app-hyper-scheduling',
  templateUrl: './hyper.scheduling.component.html',
  styleUrls: ['../shared/global.css']
})

export class HyperSchedulingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(SchedulingComponent) schedulingComponents: QueryList<SchedulingComponent>;
  @ViewChild(SchedulingGraphComponent, {static: false}) schedulingGraphComponent: SchedulingGraphComponent;
  private taskChangeSubscription_: Subscription;
  private schedulingChangeSubscription_: Subscription;
  schedulingMode: FormControl = new FormControl(null, Validators.required);
  schedulingSpeedLimit: FormControl = new FormControl(null, Validators.required);
  startSchedulingDate: FormControl = new FormControl(null, Validators.required);
  startSchedulingTime: FormControl = new FormControl(null, Validators.required);
  finishSchedulingDate: FormControl = new FormControl(null, Validators.required);
  finishSchedulingTime: FormControl = new FormControl(null, Validators.required);
  scheduling: any;
  //framePages: any;
  schedulingFlag: any = false;
  schedulingPage: number = 1;
  validSchedulingNo: number = 1;
  schedulingPages: any = ['1', '2', '3']; 
  schedulingModes: any = [{mode: 0, desc: 'Continuous'}, {mode: 1, desc: 'Periodic'}];
  
  openFileFlag: Boolean = false;

  constructor(public router: Router,
              public cache: Cache,
              public http: HttpClient) {  

      this.taskChangeSubscription_ = cache.taskChange.subscribe(
        task => {            
          if(task == '') { 
            return;
          } else if(task == 'send') {   
            if(this.checkInvalidMessage()) return; // empty!!         
            this.update_(1);
            this.scheduling[1].send(1, true);
            this.setSendFlag();
          } else if(task == 'sendScheduling') {              
            if(this.checkInvalidMessage()) return; // empty!!         
            this.update();
            for(var i = 1; i < this.validSchedulingNo + 1; ++i) {
              var flag = false;
              if(i == this.validSchedulingNo) flag = true;
              this.scheduling[i].send(this.validSchedulingNo, flag);              
            }
            this.setSendFlag();
          } else if(task == 'showScheduling') {             
            this.showScheduling();
          } else if(task == 'forwardScheduling') { 
            this.forward();
          } else if(task == 'backwardScheduling') { 
            this.backward();           
          } else if(task == 'saveScheduling') { 
            this.saveScheduling();       
          } else if(task == 'deleteFrame') { 
            this.deleteFrame();                       
          }  
          this.cache.task('');      
      });                      
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    switch(event.key) {
      case 'ArrowRight':
        this.scheduling[0].forward();
      break;
      case 'ArrowLeft':
        this.scheduling[0].backward();
      break;
      case 'Home':
        this.scheduling[0].rewind();
      break;
      case 'F9':
        if(this.schedulingFlag) this.forward();
      break;
      case 'F10':
        if(this.schedulingFlag) this.backward();
      break;
      case 'Delete':
        this.deleteFrame();
      break;
      default:
        //this.scheduling[0].keyPressed(event);
      break;
    }
  }

  setSendFlag() {
    this.scheduling[0].notSentFlag = false;
    setTimeout(() => { this.scheduling[0].notSentFlag = true; }, 30000);
  }

  checkInvalidMessage() {
    if(this.scheduling[1].frames[0].scr == "") {
      this.scheduling[0].error = true;
      this.scheduling[0].errorMessage = 'No valid message!';
      return true;  
    }
    return false;
  }

  update() {
    this.update_(this.schedulingPage);
  }

  private update_(i) {      
    var json = this.scheduling[i].json;
    json.sl = this.schedulingSpeedLimit.value;
    this.schedulingModes.forEach(m => {      
      if(m.desc == this.schedulingMode.value) {
        json.sch = m.mode;
      }}
    );
    
    json.sd = this.startSchedulingDate.value;
    json.st = this.startSchedulingTime.value;
    json.fd = this.finishSchedulingDate.value;
    json.ft = this.finishSchedulingTime.value;    
    
    var ts = new Date().toUTCString().replace(/ /g, '*');
    
    this.validSchedulingNo = 1;    
    for(var j = 1; j < this.scheduling.length; ++j) {
      if(this.scheduling[j].frames[0].scr != "") {
        this.validSchedulingNo = j;       
      } else break;
    }    

    for(var j = 0; j < this.scheduling.length - 1; ++j) {
      //this.scheduling[j + 1].json.ts = ts;
      this.scheduling[j + 1].setTimestampCommand(ts, this.validSchedulingNo.toString(), j.toString());
    }      
    try { this.schedulingGraphComponent.render(); } catch(e) {};
  }

  showScheduling() {
    if(this.schedulingFlag) {
      this.update();
      if(this.validSchedulingNo > 1) return;
    }
    this.schedulingFlag = !this.schedulingFlag;
    this.scheduling[0].showSchedulingFlag = this.schedulingFlag;    
    this.schedulingPage = 1;
    this.getScheduling(this.schedulingPage); 
    this.schedulingChangeSubscription_ = this.schedulingComponents.changes.subscribe(() => {
      this.scheduling = [];
      this.scheduling = this.schedulingComponents.toArray(); 
      for(var i = 1; i < this.scheduling.length; ++i) this.scheduling[i].page = 0;
      this.scheduling[0].page = 1;
    }); 
  }

  selectSchedulingMode(i) {
    this.scheduling[this.schedulingPage].json.sch = this.schedulingModes[i].mode;
    this.schedulingMode.setValue(this.schedulingModes[i].desc);    
    this.update();
  }

  getScheduling(i) {
    var o = this.scheduling[i].json.sl;    
    this.schedulingSpeedLimit.setValue(o);        
    
    o = this.scheduling[i].json.sch;    
    this.schedulingMode.setValue(this.schedulingModes[o].desc);
            
    o = this.scheduling[i].json.sd; 
    this.startSchedulingDate.setValue(o);
    o = this.scheduling[i].json.st;
    this.startSchedulingTime.setValue(o);

    o = this.scheduling[i].json.fd; 
    this.finishSchedulingDate.setValue(o);
    o = this.scheduling[i].json.ft; 
    this.finishSchedulingTime.setValue(o);

    this.scheduling[i].page = 0;
    this.scheduling[0].frames = this.scheduling[i].frames;
    this.scheduling[0].auxData = this.scheduling[i].auxData;
    this.scheduling[0].text = this.scheduling[i].text;
    this.scheduling[0].rewind();
  }

  forward() {    
    if(this.schedulingPage > this.schedulingPages.length) this.schedulingPage = 1;
    else this.schedulingPage += 1;
    this.getScheduling(this.schedulingPage);     
  }

  backward() {
    if(this.schedulingPage > 1) this.schedulingPage -= 1;
    else this.schedulingPage = this.schedulingPages.length + 1;
    this.getScheduling(this.schedulingPage);
  }

  saveScheduling() {
    var body = [];
    for(var i = 1; i < this.scheduling.length; ++i) {
      var msg = this.scheduling[i].normalizeScheduling2Save();
      if(msg != '') body.push({sch: i, msg: msg});
      else {
        if(i == 1) return;
        else break;
      }
    }

    var file = { type: this.scheduling[0].type, body: JSON.stringify(body) };
    const blob = new Blob([JSON.stringify(file)], { type: 'text/plain' });
    var fileName = 'CloudMsg#' + this.scheduling[1].sign + '#' + this.scheduling[1].json.ts + '.txt'; 
    saveAs(blob, fileName);// SHOULD BE FIXED UP!!!!!!!!!!
  }

  openScheduling() {
    this.openFileFlag = !this.openFileFlag;
  }

  onFileChange(event) {
    if(event.target.files && event.target.files.length > 0) {      
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        var f = atob((reader.result as string).split(',')[1]);
        try {
          var file = JSON.parse(f);      
          if(file.type != this.scheduling[0].type) {
              this.scheduling[0].error = true;
              this.scheduling[0].errorMessage = "Incompatible Sign";
              return;                        
          }
          var sch = JSON.parse(file.body);
          for(var i = 0; i < sch.length; ++i) {                        
            var msg = sch[i].msg;
            var js = JSON.parse(msg);
            this.scheduling[i + 1].json = js;            
            var fms = JSON.parse(msg);
            this.scheduling[i + 1].frames = fms.fms;
            this.scheduling[i + 1].page = 0;            
          }         
          this.scheduling[0].json = this.scheduling[1].json;
          this.scheduling[0].frames = this.scheduling[1].frames; 
          this.getScheduling(1); 
          this.schedulingGraphComponent.render();
        } catch(e) {
          this.cache.task('');
        };                
      }          
    }      
  }

  deleteFrame() {
    this.scheduling[0].clear();        
    this.scheduling[this.schedulingPage].frames = this.scheduling[0].frames;        
    this.scheduling[this.schedulingPage].auxData = this.scheduling[0].auxData;
  }
  
  showRetrieveFlag: Boolean = false;
  retrieveRequested: Boolean = false;

  cancel() {
    this.showRetrieveFlag = false; 
  }

  showRetrieve() {
    this.showRetrieveFlag = !this.showRetrieveFlag;
  }

  retrieve() {
    this.showRetrieveFlag = false;
    var sign = this.scheduling[0].sign;
    var serialNo = this.scheduling[0].serialNo;
    var branch = this.scheduling[0].branch;
    this.http.post('/sign/dump', {sign_in: sign, serial_no_in: serialNo, branch_in: branch}).subscribe(json => {       
      try {        
        var device = JSON.parse(JSON.stringify(json))[0];        
        var qvar = {sign_in: sign, serial_no_in: serialNo, branch_in: branch, 
          ts_in: device.mts}; //, index_in: device.mix.toString(), checksum_in: device.mcs.toString()};        
        this.http.post('/message/retrieve', qvar).subscribe(
            json_ => {                        
          try {
            var json = JSON.parse(JSON.stringify(json_));        
            //console.log(json);         
            for(var i = 0; i < json.length; ++i) {            
              if(json[i].index == i) {
                var file = JSON.parse(json[i].file);             
                if(file.fms[0].scr != '') {
                  this.scheduling[i + 1].json = file; 
                  this.scheduling[i + 1].frames = this.scheduling[i + 1].json.fms;
                }               
              }                         
            }
          } catch(ex) {}           
          this.scheduling[0].frames = this.scheduling[1].frames; 
          this.getScheduling(this.schedulingPage);                 
          this.schedulingGraphComponent.render();
          this.retrieveRequested = true;          
          setTimeout(() => { this.retrieveRequested = false; }, 60000);             
          this.cancel();
        });        
      } catch(e) {};      
    }); 
  }

  ngOnInit() {        
  }

  ngAfterViewInit() {
    this.scheduling = [];
    this.scheduling = this.schedulingComponents.toArray();    
    this.getScheduling(this.schedulingPage);      
  }

  ngOnDestroy() { 
    this.taskChangeSubscription_.unsubscribe();  
    if(this.schedulingChangeSubscription_) this.schedulingChangeSubscription_.unsubscribe();
  }
}
