import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../shared/global.css']
})

export class LoginComponent implements OnInit, OnDestroy {
  username: FormControl = new FormControl();
  password: FormControl = new FormControl();
  loginGroup: FormGroup;
  private branchChangeSubscription_: Subscription;
  debounce: Boolean = false;  
  user: any;
  branches: any;
  forgotFlag: Boolean = false;
  forgotSent: Boolean = false;
  constructor(
      @Inject(FormBuilder) formBuilder:FormBuilder, 
      private http: HttpClient, 
      private router: Router,
      private cache: Cache) {   
        this.loginGroup = formBuilder.group({      
            username: this.username,
            password: this.password      
        });
      
      this.branchChangeSubscription_ = cache.branchListChange.subscribe(
        branch => {          
        this.branches = branch;    
      });
  }

  setDebounce() {
    this.debounce = true;    
    setTimeout(() => {this.debounce = false;}, 100);
  }

  login() {    
    this.setDebounce();
    this.http.post('/authenticate/user', this.loginGroup.value).subscribe(user => {            
        this.user = user;           
        if(this.user.success) {
          this.debounce = true;  
          var accessList = ["web_admin", "service_admin", "service_tech", "web_visitor", "service_visitor", 
                          "company_admin", "company_visitor", "branch_admin", "branch_tech", "branch_visitor"];
          var command = ["Firmware Upgrade", "Rename Sign", "LDR Values", "Brightness Levels",  "Sign On", "Sign Off", "Brightness Control", "Speed Limit", "Time Zone", "DLS Start", 
                          "DLS Finish", "SMS Password"];
          var rscsCommand = ["Update Date Time", "Device Parameters", "Device Mode"];
          if(this.user.access != 'web_admin') {
            accessList.splice(0, 2);
            command.splice(0, 1);
            if(this.user.access != 'service_admin') {
              accessList.splice(0, 1);
              if(this.user.access != 'service_tech') {
                accessList.splice(0, 3);
                command.splice(0, 3);
                if(this.user.access != 'company_admin') {
                  accessList.splice(0, 2);
                  if(this.user.access != 'branch_admin') {
                    accessList.splice(0, 2);
                  }                  
                }
              }
            }
          }      
          this.user.accessList = accessList;          
          this.user.command = command;
          this.user.rscsCommand = rscsCommand;
          this.cache.setUser(this.user);        
          this.router.navigate(['branch']);   
        }                      
    }, error => {}) 
  }

  requestResetPassword() {
    this.setDebounce();
    this.http.post('/user/reset/password', this.loginGroup.value).subscribe(user => { this.forgotSent = true;}, error => {}) 
  }

  ngOnInit() { 
    if(!this.branches) {
      this.http.post('/branch/list', {}).subscribe(branch => {
        this.cache.setBranchList(branch);  
        this.router.navigate(['branch']);             
      });
    } 
  }

  ngOnDestroy() {
    this.branchChangeSubscription_.unsubscribe();    
  }
}
