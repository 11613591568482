import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Cache } from '../shared/cache.service'
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css', '../shared/global.css']
})

export class ToolbarComponent implements OnInit, OnDestroy { 
  @Input() titleOff: Boolean = false;
  @Input() set userPageFlag_(value: Boolean) {
    this.userPageFlag = value;    
  }
  private userChangeSubscription_: Subscription;
  private taskChangeSubscription_: Subscription;
  //private webPageChangeSubscription_: Subscription;    
  userCache: any;    
  logoutFlag: Boolean = false;
  adminAccessFlag: Boolean = false;
  serviceAccessFlag: Boolean = false;
  companyAccessFlag: Boolean = false;
  branchAccessFlag: Boolean = false;
  userPageFlag: Boolean = false;  
  
  constructor(private router: Router, 
    private cache: Cache) {      
      this.userChangeSubscription_ = cache.userChange.subscribe(user => { 
        this.userCache = user;         
        if(user.access == 'web_admin') this.adminAccessFlag = true;
        if(user.access == 'service_admin' || user.access == 'service_tech' || this.adminAccessFlag)  this.serviceAccessFlag = true;        
        if(user.access == 'company_admin' || this.serviceAccessFlag) this.companyAccessFlag = true;
        if(user.access == 'branch_admin' || this.companyAccessFlag) this.branchAccessFlag = true;        
      });
      //this.webPageChangeSubscription_ = cache.webPageChange.subscribe(newWebPage => { });
  }

  task(cmd): void { this.cache.task(cmd); }

  message(): void { this.router.navigate(['message']); }
  archive(): void { this.router.navigate(['archive']); }
  image(): void { this.router.navigate(['image']); }
  login(): void { this.router.navigate(['login']); }
  company(): void { this.router.navigate(['company']); }
  branch(): void { this.router.navigate(['branch']); }
  user(): void { this.router.navigate(['user']); }
  password(): void { this.router.navigate(['user/change/password']); }
  sign(): void { this.router.navigate(['sign']); }
  display(): void { this.router.navigate(['display']); }  
  board(): void { this.router.navigate(['board']); }  
  hex(): void { this.router.navigate(['hex']); }  
  logout(): void {  this.logoutFlag = true; }
  logoutConfirmed() {
    this.cache.logout();
  }
  cancel() { this.logoutFlag = false; }

  ngOnInit() { }

  ngOnDestroy() {
    this.userChangeSubscription_.unsubscribe();
    //this.webPageChangeSubscription_.unsubscribe();    
  }
}
