import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rscsstatus',
  templateUrl: './rscsstatus.component.html',
  styleUrls: ['../../shared/global.css']
})
export class RSCSStatusComponent {

  @Input() set status(value: any) {
    this.table = []; 
    try {
      var speed = value.qur.split(',', 4);
      var time = value.mei.split(',',4);
      var flags = value.msc.split(',', 7);
  
      this.table.push({k: 'RSCS Local Time', v: value.ltm + ' (' + (value.zon / 2) + ')'});
      this.table.push({k: 'Linux UTC Time', v: value.utc});
      
  
      var flag = 'No';
      if(value.son == 'N') flag = 'Yes';
      this.table.push({k: 'Dispaly ON', v: flag});
  
      flag = 'Speed Limit';
      if(flags[0] == 1) flag = 'RADAR Speed';
      this.table.push({k: 'Second Frame Mode', v: flag});
  
      var bgm = ' Auto';     
      var chargeMode = 'Boost';
      if(value.chm == 'A') chargeMode = 'Absorption';
      else if(value.chm == 'F') chargeMode = 'Float';
      this.table.push({k: 'Battery', v: value.mnv / 10 + 'V ' + chargeMode});
      this.table.push({k: 'Solar', v: value.s1v / 10 + 'V, ' + value.s1c / 10 + 'A'});
  
      if(value.bgm == 'M') bgm = ' Manual';
      var brt = 255 - value.brt;   
      this.table.push({k: 'Brightness', v: brt + bgm});
      this.table.push({k: 'LDR Value', v: value.ldr}); 
  
       this.table.push({k: 'Speed Limit', v: Number(speed[0])});
      this.table.push({k: 'Maximum Speed', v: Number(speed[1])});
      this.table.push({k: 'Minimum Speed', v: Number(speed[2])});
      this.table.push({k: 'Slow Down No', v: Number(speed[3])});
  
      this.table.push({k: 'Frame 1 Time', v: Number(time[0])});
      this.table.push({k: 'Frame 2 Time', v: Number(time[1])});
      this.table.push({k: 'Frame 3 Time', v: Number(time[2])});
      this.table.push({k: 'Blank Time', v: Number(time[3])});    
  
  
      this.table.push({k: 'Read RADAR Speed', v: value.spd});
      this.table.push({k: 'Records No', v: Number(flags[1])});
  
      flag = 'Not Connected';
      if(flags[3] == 0) flag = 'Connected';
      this.table.push({k: 'Touch Screend', v: flag});     
  
      flag = 'Unknown';
      if(flags[6] == 1) flag = 'AGD 331';
      if(flags[6] == 2) flag = 'Houston';
      this.table.push({k: 'RADAR Type', v: flag});
      /*
      flag = 'Not in Progress';
      if(flags[2] == 1) flag = 'In Progress';
      this.table.push({k: 'Backup', v: flag});
      flag = 'Not Connected';
      if(flags[4] == 1) flag = 'Connected';
      this.table.push({k: 'OTG', v: flag});
      flag = 'Not Connected';
      if(flags[5] == 1) flag = 'Connected';
      this.table.push({k: 'Bluetooth', v: flag});
      flag = 'Unknown';
      flag = 'Not Connected';
      if(flags[7] == 1) flag = 'Connected';
      this.table.push({k: 'WiFi', v: flag});
      */   
  
      this.table.push({k: 'Temperature', v: value.tmp + 'C'});
      //this.table.push({k: 'Query', v: value.qur});     
  
      
      this.table.push({k: 'WiFi IP', v: value.scn});
      var watchdog = 'Yes';
      if(value.wdg = 'N') watchdog = 'No'
      this.table.push({k: 'Watchdog', v: watchdog});    
      this.table.push({k: 'Board & Type', v: value.bnm + ' (' + value.typ + ') '});
      this.table.push({k: 'Software Version', v: value.swv});
    } catch(e) {}; 
    //console.log(value);

  };
  @Input() show: Boolean = true;
  table: any = [];

}
