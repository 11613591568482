import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';

@Component({
  selector: 'app-hex',
  templateUrl: './hex.component.html',
  styleUrls: ['./hex.component.css', '../shared/global.css']
})

export class HexComponent implements OnInit, OnDestroy {
  file:  FormControl = new FormControl(null, Validators.required);
  board: FormControl = new FormControl(null, Validators.required);
  name: FormControl = new FormControl(null, Validators.required);
  private taskChangeSubscription_: Subscription;
  //addBranchGroup: FormGroup;
  addHexGroup: FormGroup;
  files: any;
  names : any;
  boards: any;
  showAddFlag: Boolean = false;
  showEditFlag: Boolean = false;
  showDeleteFlag: Boolean = false;
  editIndex: any = -1;
  constructor(@Inject(FormBuilder) formBuilder:FormBuilder, private http: HttpClient, private cache: Cache) {
    this.addHexGroup = formBuilder.group({  
      file_in: this.file,    
      board_in: this.board,
      name_in: this.name,       
    });

    this.taskChangeSubscription_ = cache.taskChange.subscribe(
      task => {
        if(task == 'edit') { 
          this.showEditFlag = !this.showEditFlag;
          this.showDeleteFlag = false;
          this.editIndex = -1;   
        }        
    });
  }

  showAddForm() {
    this.showAddFlag = !this.showAddFlag;
    this.editIndex = -1;     
    this.name.setValue('');
  }

  cancel() {
    this.showAddFlag = false;   
    this.showEditFlag = false;   
    this.showDeleteFlag = false;
    this.editIndex = -1;  
  }

  remove(i) {        
    if(this.showDeleteFlag) {      
      this.http.post('/hex/remove', {name_in: this.names[i].hnm}).subscribe(
        hex => { 
          this.names = hex;
          this.cancel();           
        }, error => { this.names = []; });
    } else {
      this.showDeleteFlag = true;
      this.editIndex = i;
    };
  }

  selectBoard(i) {
    this.addHexGroup.patchValue({board_in: this.boards[i].bnm});
    for(var j = 0; j < this.files.length; ++j) {
      this.files[j].board_in = this.boards[i].bnm;
    }   
  }

  updateNameForm() {    
    for(var j = 0; j < this.files.length; ++j) {
      this.files[j].name_in = this.name.value;
    }
  }

  splitHexFiles(str) {
    this.files = [];
    var maxSize = 37000;
    var strLen = str.length;
    var start = 0;
    var finish = maxSize;
    for(var i = 0; i < 50; ++i) {
      if(finish <= strLen) {
        while(str.charAt(finish) != ':') {
          finish -= 1;
          if(finish < 0) break;
        } 
      }

      this.files.push({
        board_in: this.board.value,
        name_in: this.name.value,
        index_in: (i + 1).toString(),              
        file_in: str.slice(start, finish)
      }); 

      if(finish <= strLen) {
        start = finish;
        finish = start + maxSize;        
      } else {
        break;
      }
    }
  }

  loadFiles(event) {
    var promise = [];           
    if(event.target.files && event.target.files.length > 0) {      
      for(var i = 0; i < 1; ++i) {        
        promise.push(new Promise((resolve, reject) => {
          let fileName = event.target.files[0].name;
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]);
          this.addHexGroup.patchValue({file_in: fileName});
          reader.onload = () => {
            var str = (reader.result as string).split(',')[1];
            this.splitHexFiles(atob(str));                     
            resolve();
          }
        }));      
      }
    }
    return promise;
  }

  onFileChange(event) {
    var promise = this.loadFiles(event);
    Promise.all(promise).then((values) => {      
    });
  }

  onSubmit() {
    //this.loading = true;
    var promise = [];
    for(var i = 0; i < this.files.length; ++i) {
      promise.push(new Promise((resolve, reject) => {        
        this.http.post('/hex/add', this.files[i]).subscribe(name => { 
            this.names = name;  
            this.cancel();          
            resolve();           
          });              
      }));
    }
  }

  ngOnInit() {
    if(!this.names) {
      this.http.post('/hex/list', {}).subscribe(hex => {
        this.names = hex;
      });
    }

    if(!this.boards) {
      this.http.post('/board/list', {}).subscribe(board => {
        this.boards = board;
      });
    }
  }

  ngOnDestroy() {     
    this.taskChangeSubscription_.unsubscribe();
  }
}
