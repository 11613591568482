import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css', '../shared/global.css']
})

export class CompanyComponent implements OnInit, OnDestroy {
  private companyChangeSubscription_: Subscription;
  private taskChangeSubscription_: Subscription;
  private userChangeSubscription_: Subscription;
  user: any;
  companies: any;
  showAddFlag: Boolean = false;
  showEditFlag: Boolean = false;
  showDeleteFlag: Boolean = false;
  editIndex: any = -1;

  constructor(
    @Inject(FormBuilder) formBuilder:FormBuilder, 
    private http: HttpClient, 
    private cache: Cache) {

      this.companyChangeSubscription_ = cache.companyListChange.subscribe(
        company => {          
        this.companies = company;    
      });

      this.taskChangeSubscription_ = cache.taskChange.subscribe(
        task => {
          if(task == 'edit') { 
            this.showEditFlag = !this.showEditFlag;
            this.showDeleteFlag = false;
            this.editIndex = -1;            
          }        
      });

      this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.user = user; });
      this.cancel();
  }  

  showAddForm() {
    this.showAddFlag = !this.showAddFlag;
    this.editIndex = -1;  
  }

  cancel() {
    this.showAddFlag = false;   
    this.showEditFlag = false;   
    this.showDeleteFlag = false;
    this.editIndex = -1;  
  }

  showEditForm(i) {    
    this.editIndex = i;    
    this.showEditFlag = false;
    this.showAddFlag = false;
  }

  remove(i) {
    this.showDeleteFlag = true;
    this.editIndex = i;
  }

  ngOnInit() {
    if(!this.companies) {
      this.http.get('/company/list').subscribe(company => {
        this.cache.setCompanyList(company);        
      });
    }
  }

  ngOnDestroy() {
    this.companyChangeSubscription_.unsubscribe();    
    this.taskChangeSubscription_.unsubscribe();
    this.userChangeSubscription_.unsubscribe();
  }
}
