import { Component, Input, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { DeviceService } from './device.service';
import { Cache } from '../shared/cache.service';

@Component({
  selector: 'app-command',
  templateUrl: './command.component.html',
  styleUrls: ['../shared/global.css'],
  providers: [DeviceService]
})

export class CommandComponent implements OnInit, OnDestroy {
    @Input() sign: any;
    @Input() branch: any;
    @Input() serialNo: any;
    @Input() show: Boolean;
    commandType: FormControl = new FormControl();
    hex: FormControl = new FormControl();
    device: FormControl = new FormControl();
    ldr1Val1: FormControl = new FormControl();
    ldr1Val2: FormControl = new FormControl();
    ldr1Val3: FormControl = new FormControl();
    dimmingVal1: FormControl = new FormControl();
    dimmingVal2: FormControl = new FormControl();
    dimmingVal3: FormControl = new FormControl();
    zone: FormControl = new FormControl();
    dlsStart: FormControl = new FormControl();
    dlsFinish: FormControl = new FormControl();
    smsPassword: FormControl = new FormControl();
    speedLimit: FormControl = new FormControl();
    brightness: FormControl = new FormControl();
    command: FormControl = new FormControl(null, Validators.required);
    commandGroup: FormGroup;
    private userChangeSubscription_: Subscription;        
    user: any;  
    hexes: any;
    zones: any = ['QLD', 'NSW', 'VIC', 'ACT', 'TAS', 'NT', 'SA', 'WA', 'NZST'];
    showDroplist: Boolean = false;

    constructor(@Inject(FormBuilder) formBuilder:FormBuilder, 
        private http: HttpClient, 
        private cache: Cache) {
        this.commandGroup = formBuilder.group({     
            sign_in: this.sign,
            branch_in: this.branch,
            command_in: this.command,
            serial_no_in: this.serialNo               
        });
        this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.user = user; });                
    }

    selectCommand(i) {
        this.commandGroup.patchValue({
            sign_in: this.sign,
            branch_in: this.branch,
            command_in: this.command.value,
            serial_no_in: this.serialNo  
        });

        if(this.user.command[i] == 'Firmware Upgrade') {
            if(!this.hexes) {
                this.http.post('/hex/list', {}).subscribe(hex => {
                  this.hexes = hex;                  
                });
            }            
        } else if(this.user.command[i] == 'Sign On' || this.user.command[i] == 'Sign Off') {
            this.command.setValue(this.user.command[i] + ';');
        } else if(this.user.command[i] == 'Brightness Control') {
            this.command.setValue('BRIGHTNESS 1;');
        } else {
            this.command.setValue(null);            
            this.device.setValue(null);                    
        }  
        this.commandType.setValue(this.user.command[i]);    
        if(this.user.command[i] != 'Time Zone') this.showDroplist = false;  
    }

    selectHex(i) {
        var h = this.hexes[i].hnm;
        this.hex.setValue(h);
        this.command.setValue('Upgrade ' + this.hexes[i].hin + '$' + h + ';');
    }

    onDevice() {
        var d = this.device.value;
        if(d) this.command.setValue('Device ' + d + ';');
        else this.command.setValue(null); 
    }

    onLDR1() {
        var v1 = this.ldr1Val1.value;
        var v2 = this.ldr1Val2.value;
        var v3 = this.ldr1Val3.value;
        if(v1 && v2 && v3) this.command.setValue('LDR1 ' + v1 + ' ' + v2 + ' ' + v3 + ';')
        else this.command.setValue(null);
    }

    onDimming() {
        var v1 = this.dimmingVal1.value;
        var v2 = this.dimmingVal2.value;
        var v3 = this.dimmingVal3.value;
        this.command.setValue(null);        
        if(v1 && v2 && v3) {
            if(v1 > 255 || v2 > 255 || v3 > 255) return
            var v11 = 255 - v3;
            v2 = 255 - v2;
            var v33 = 255 - v1;
            this.command.setValue('DIMMING ' + v11 + ' ' + v2 + ' ' + v33 + ';')
        }       
    }

    selectZone(i) {
        var z = this.zones[i];
        this.zone.setValue(z);
        this.command.setValue('State ' + z + ';');
        this.showDroplist = false;   
    }

    reformatDateTime(t: string) {
        var str = t.replace(/-/g, ':');
        return str.replace(/T/g, ' ');
    }

    onDLSStart() {
        var d = this.dlsStart.value;
        if(d) this.command.setValue('DLS Start ' + this.reformatDateTime(this.dlsStart.value) + ';');        
        else this.command.setValue(null);
    }

    onDLSFinish() {
        var d = this.dlsFinish.value;
        if(d) this.command.setValue('DLS Finish ' + this.reformatDateTime(this.dlsFinish.value) + ';');        
        else this.command.setValue(null);
    }

    onSMSPassword() {
        var d = this.smsPassword.value;
        if(d) this.command.setValue('PASSWORD1 ' + d + ';');
        else this.command.setValue(null); 
    }

    onSpeedLimit() {
        var d = this.speedLimit.value;
        if(d) this.command.setValue('SPEED ' + d + ';');
        else this.command.setValue(null); 
    }

    onBrightness() {
        var d = this.brightness.value;
        if(d) this.command.setValue('BRIGHTNESS ' + d + ';');
        else this.command.setValue(null); 
    }

    onCommand() {
        this.http.post('/sign/command', this.commandGroup.value).subscribe(cmd =>  {
            (<any>window).ga('send', 'event', {
                eventCategory: 'Command',
                eventLabel: this.user.email,
                eventAction: this.command.value,
                eventValue: 4
            });            
            this.command.setValue(null);
            this.show = false;
        });
    }

    ngOnInit() { }

    ngOnDestroy() { 
        this.userChangeSubscription_.unsubscribe();           
    } 
}