import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MessageComponent } from './message.component';
import { SharedModule } from '../shared/shared.module';

const setupRoutes: Routes = [
    {
    path: 'message',
    component: MessageComponent
    }
];

@NgModule({
  declarations: [
    MessageComponent, 
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forChild(setupRoutes)
  ],
  bootstrap: [
    MessageComponent
  ],
  exports: [
    MessageComponent  
  ]
})

export class MessageModule {}