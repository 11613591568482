import { Component, Input, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { DeviceService } from '../device.service';
import { Cache } from '../../shared/cache.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-rscsgraph',
  templateUrl: './rscsgraph.component.html',
  styleUrls: ['./rscsgraph.component.css']
})
export class RSCSGraphComponent implements OnInit, OnDestroy {
    @Input() sign: any;
    @Input() branch: any;
    @Input() serialNo: any;
    //@Input() show: Boolean; 
    @Input() set show_(value: Boolean) {
      this.show = value;
      this.warning = false;
      this.logStartDate.setValue(null);
      this.logFinishDate.setValue(null);
    }  
    @Input() warning: Boolean; 
    show: Boolean; 
    logStartDate: FormControl = new FormControl();
    logFinishDate: FormControl = new FormControl();
    downloadLog: FormControl = new FormControl(null, Validators.required);
    downloadLogGroup: FormGroup;
    private userChangeSubscription_: Subscription;        
    user: any; 
    s_epoch: number = 0;
    f_epoch: number = 0;

  constructor(@Inject(FormBuilder) formBuilder:FormBuilder,
          private http: HttpClient,
           private cache: Cache) { 
      
      this.downloadLogGroup = formBuilder.group({     
            sign_in: this.sign,
            branch_in: this.branch,            
            serial_no_in: this.serialNo,
            start_epoch: this.s_epoch,
            finish_epoch: this.f_epoch                 
        }); 

        this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.user = user; }); 
        //this.warning = false;
    }

   onDateUpdated() {   
      this.downloadLogGroup.patchValue({
            sign_in: this.sign,
            branch_in: this.branch,            
            serial_no_in: this.serialNo,
            start_epoch: this.s_epoch,
            finish_epoch: this.f_epoch   
        }); 
        console.log(this.downloadLogGroup.value);    
    }

    onStartDateUpdated() {
      this.s_epoch = new Date(this.logStartDate.value).getTime() / 1000;
      this.onDateUpdated();
    }
    onFinishDateUpdated() {
      this.f_epoch = new Date(this.logFinishDate.value).getTime() / 1000;
      this.onDateUpdated();
    }
 

  onDownload() {
    //console.log(this.downloadLogGroup.value);
    this.warning = true;
    this.http.post('/rscs/dump/sign', this.downloadLogGroup.value).subscribe(log =>  {
            this.warning = false; 
            (<any>window).ga('send', 'event', {
                eventCategory: 'DownloadLog',
                eventLabel: this.user.email,
                eventAction: this.downloadLog.value,
                eventValue: 4
            });          
            
            var log_ = JSON.parse(JSON.stringify(log));
            //console.log(log_);
            var output = '';
            for(var i = 0; i < log_.length; ++i) {
              var date = new Date(log_[i].dt * 1000);
              //var time = new Time(log_[i].dt * 1000);
              output += date.toLocaleDateString() + ',' + date.toLocaleTimeString() + ',' + log_[i].spd + ',' + log_[i].spl + '\n\r';
              
            }
            //console.log(output);
            //var file = { body: JSON.stringify(output) };
            const blob = new Blob([output], { type: 'text/plain' });
            var fileName = 'RSCS#' + this.sign + '#'  + this.logStartDate + '#' + this.logFinishDate + '.csv'; 
            saveAs(blob, fileName);
            this.logStartDate.setValue(null);
            this.logFinishDate.setValue(null);
            this.show = false;
            this.onStartDateUpdated();
        });
  }
  ngOnInit() {
    this.warning = false;
  }

  ngOnDestroy() { 
        this.userChangeSubscription_.unsubscribe();           
  } 

}
