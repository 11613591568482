import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { DeviceService } from './device.service';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['../shared/global.css'],
  providers: [DeviceService]
})

export class DeviceComponent implements OnInit, OnDestroy {  
  @Input() sign: String;
  @Input() serialNo: String;
  @Input() branch: String;
  @Input() techAccessFlag: Boolean = false;
  @Input() rscsTrailerType: number = 35;
  @Input() rscsTripodType: number = 34;
  private statusChangeSubscription_: Subscription;
  private messageChangeSubscription_: Subscription;
  status: any
  message: any;
  commandShow: Boolean = false;
  rscsCommandShow: Boolean = false;
  rscsDownloadShow: Boolean = false;
  mapShow: Boolean = false;
  statusShow: Boolean = false;
  rscsStatusShow: Boolean = false;
  splashShow: Boolean = false;  
  maxWidth: number = 150;
  listMode: Boolean = false;
  moreLessMsg: any = "Click to expand";

  constructor(private http: HttpClient,
              private router: Router,
              private device: DeviceService) { 
    this.statusChangeSubscription_ = device.statusChange.subscribe(
        status => {          
        this.status = status;                     
    });

    this.messageChangeSubscription_ = device.messageChange.subscribe(
        message => {
        this.message = message;                      
     });
  }

  canvas() {
    this.router.navigate(['display/hyperScheduling', {type: this.status.typ, sign: this.sign, serialNo: this.serialNo, branch: this.branch}]);
  }

  flipCommand() {
    this.commandShow = !this.commandShow;    
    this.mapShow = false; 
    this.statusShow = false; 
    this.splashShow = false; 
    this.rscsStatusShow = false;
    this.rscsCommandShow = false;
    this.rscsDownloadShow = false;
  }

  flipMap() {
    this.mapShow = !this.mapShow;  
    this.commandShow = false;
    this.statusShow = false; 
    this.splashShow = false; 
    this.rscsStatusShow = false;
    this.rscsCommandShow = false;
     this.rscsDownloadShow = false;
  }

  flipStatus() {
    this.statusShow = !this.statusShow;  
    this.mapShow = false; 
    this.commandShow = false;
    this.splashShow = false;
    this.rscsStatusShow = false;
    this.rscsCommandShow = false;
     this.rscsDownloadShow = false;
  }

  flipSplash() {
    this.splashShow = !this.splashShow;
    this.mapShow = false; 
    this.commandShow = false;
    this.statusShow = false;
    this.rscsStatusShow = false;
    this.rscsCommandShow = false;
     this.rscsDownloadShow = false;
  }
  
  flipRSCSStatus() {
    this.rscsStatusShow = !this.rscsStatusShow;
    this.splashShow = false;
    this.mapShow = false; 
    this.commandShow = false;
    this.statusShow = false;
    this.rscsCommandShow = false;
     this.rscsDownloadShow = false;
  }

  flipRSCSCommand() {
    this.rscsCommandShow = !this.rscsCommandShow;
    this.rscsStatusShow = false;
    this.splashShow = false;
    this.mapShow = false; 
    this.commandShow = false;
    this.statusShow = false;
     this.rscsDownloadShow = false;
  }

  flipRSCSDownload() {
    this.rscsDownloadShow = !this.rscsDownloadShow;
    this.rscsStatusShow = false;
    this.splashShow = false;
    this.mapShow = false; 
    this.commandShow = false;
    this.statusShow = false;
    this.rscsCommandShow = false;
  }

  ts: any;
  index: any;
  checksum: any;
  update() {
    this.http.post('/sign/dump', {sign_in: this.sign, serial_no_in: this.serialNo, branch_in: this.branch}).subscribe(json => {       
      try {        
        var device_ = JSON.parse(JSON.stringify(json))[0];
        this.device.setStatus(device_);    
        if(this.status.typ == 34 || this.status.typ ==35) return;    
        if(this.ts != this.status.mts || this.index != this.status.mix.toString() || this.checksum != this.status.mcs.toString()) {
          this.ts = this.status.mts;
          this.index = this.status.mix.toString();
          this.checksum = this.status.mcs.toString();
          var qvar = {sign_in: this.sign, serial_no_in: this.serialNo, branch_in: this.branch, ts_in: this.ts, index_in: this.index, checksum_in: this.checksum };
          this.http.post('/message/dump', qvar).subscribe(
              json_ => {            
            var json = JSON.parse(JSON.stringify(json_))[0];
            json.file = JSON.parse(json.file);             
            this.device.setMessage(json);
          });
        }
      } catch(e) {};      
    });
  }
  
  updateSize() {
    if(this.maxWidth < 800) {
      this.maxWidth = 800;
      this.listMode = true;
      this.moreLessMsg = "Click to shrink";
    } else {
      this.maxWidth = 150;
      this.listMode = false;
      //if(this.status.typ==this.rscsTrailerType || this.status.typ==this.rscsTripodType) this.listMode = true; 
      this.moreLessMsg = "Click to expand";
      this.splashShow = false;
      this.mapShow = false; 
      this.commandShow = false;
      this.statusShow = false;
      this.rscsStatusShow = false;
      this.rscsCommandShow = false;
    } 
  }

  tick: any;
  ngOnInit() {
    var refugeeCnt = 0;
    this.update();
    this.tick = setInterval(() => {
      this.update();
      if(++refugeeCnt == 60) {
        this.router.navigate(['refugee']);
      }
    }, 60000);
  }

  ngOnDestroy() {    
    this.statusChangeSubscription_.unsubscribe();  
    this.messageChangeSubscription_.unsubscribe();  
    clearInterval(this.tick);        
  } 
}