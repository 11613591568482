import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-refugee',  
  templateUrl: './refugee.component.html',
  styleUrls: ['../shared/global.css']
})

export class RefugeeComponent { 
    constructor(private router: Router) { 
    }

    back() {
        this.router.navigate(['display']);
    }
}