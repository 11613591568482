import { Component, Input, OnDestroy } from '@angular/core';
import { Cache } from './cache.service'
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./global.css']
})

export class LogoComponent implements OnDestroy { 
  @Input() show: Boolean = true;
  private userChangeSubscription_: Subscription;   
  userCache: any;   
  
  constructor(private cache: Cache) {      
      this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.userCache = user; });      
  }

  ngOnDestroy() {
    this.userChangeSubscription_.unsubscribe();   
  }
}
