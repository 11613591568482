import { Component, Input, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Cache } from '../shared/cache.service';
import { CompanyComponent } from './company.component';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit.company.component.html',
  styleUrls: ['./company.component.css', '../shared/global.css']
})

export class EditCompanyComponent {
  @Input() companies: any;
  @Input() user: any;
  @Input() index: any;
  private companyComponent: CompanyComponent;
  company_old: FormControl = new FormControl();
  company_new: FormControl = new FormControl(null, Validators.required);
  editCompanyGroup: FormGroup;

  constructor(
    @Inject(FormBuilder) formBuilder:FormBuilder, 
    private http: HttpClient, 
    private cache: Cache,
    companyComponent: CompanyComponent) {
      this.companyComponent = companyComponent;
      this.editCompanyGroup = formBuilder.group({  
        company_old: this.company_old,     
        company_new: this.company_new              
      });
  }  

  editForm() {
    if(this.editCompanyGroup.valid) {
      this.company_old.setValue(this.companies[this.index].cnm);      
      this.http.post('/company/edit', this.editCompanyGroup.value).subscribe(
        company => { 
          this.cache.setCompanyList(company); 
          this.cancel(); 
          (<any>window).ga('send', 'event', {
            eventCategory: 'Company Edit',
            eventLabel: this.user.email,
            eventAction: this.company_new.value,
            eventValue: 2
          });

        });        
    }
  }

  cancel() {
     this.companyComponent.cancel();
  }
}
