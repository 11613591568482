import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status',  
  templateUrl: './status.component.html',
  styleUrls: ['../shared/global.css']})

  
export class StatusComponent {  
  @Input() set status(value: any) {
    this.table = [];        
    this.table.push({k: 'Local Time', v: value.ltm + ' (' + (value.zon / 2) + ')'});
    var chargeMode = 'Boost';
    if(value.chm == 'A') chargeMode = 'Absorption';
    else if(value.chm == 'F') chargeMode = 'Float';
    this.table.push({k: 'Battery', v: value.btv / 10 + 'V ' + chargeMode});
    this.table.push({k: 'Solar (1)', v: value.s1v / 10 + 'V, ' + value.s1c / 10 + 'A'});
    this.table.push({k: 'Solar (2)', v: value.s2v / 10 + 'V, ' + value.s2c / 10 + 'A'});
    this.table.push({k: 'Temperature', v: value.tmp + 'C'});
    this.table.push({k: 'Message', v: value.mts + ' ~ ' + value.msc});
    this.table.push({k: 'Message Status', v: value.mei});
    this.table.push({k: 'Query', v: value.qur});
    var bgm = ' Auto';
    if(value.bgm == 'M') bgm = ' Manual';
    var brt = 255 - value.brt;   
    var bmn = 255 - value.bmx; // dimming to brightness convertion
    var bmd = 255 - value.bmd; // dimming to brightness convertion
    var bmx = 255 - value.bmn; // dimming to brightness convertion
    this.table.push({k: 'Brightness', v: brt + bgm});
    this.table.push({k: 'Brightness Factors', v: bmn + ', ' + bmd + ', ' + bmx});
    this.table.push({k: 'LDR Value', v: value.ldr});        
    this.table.push({k: 'LDR Factors', v: value.l1f + ', ' + value.l2f + ', ' + value.lhs});
    this.table.push({k: 'Speed Limit', v: value.spl});    
    this.table.push({k: 'RADAR Speed', v: value.spd});    
    this.table.push({k: 'Sign Speed', v: value.vhs});    
    var dst = 'Yes';
    if(value.dst == 'N') dst = 'No';
    this.table.push({k: 'DLS Enable', v: dst});
    this.table.push({k: 'Day Light Saving', v: value.dss + ' ~ ' + value.dsf });  
    this.table.push({k: 'UTC Time', v: value.utc});
    //this.table.push({k: 'Angle', v: value.ang}); 
    //this.table.push({k: 'Switch', v: value.swt}); 
    this.table.push({k: 'Signal Quality', v: value.sgq * 20 + '%'});
    this.table.push({k: 'Service Center Phone', v: value.scn});
    this.table.push({k: 'SMS Password', v: value.spw});
    this.table.push({k: 'Security Password', v: value.pwd});
    var stack = 'Yes';
    if(value.sov = 'N') stack = 'No'
    this.table.push({k: 'Stack Overflow', v: stack});    
    this.table.push({k: 'File Error', v: value.fer}); 
    var watchdog = 'Yes';
    if(value.wdg = 'N') watchdog = 'No'
    this.table.push({k: 'Watchdog', v: watchdog});    
    this.table.push({k: 'Board & Type', v: value.bnm + ' (' + value.typ + ') '});
    this.table.push({k: 'Software Version', v: value.swv});
  };
  @Input() show: Boolean = true;
  table: any = [];
}