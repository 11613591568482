import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UserComponent } from './user.component';
//import { AddUserComponent } from './add.usr.component';
import { SharedModule } from '../shared/shared.module';
import { PasswordComponent } from './password.component';

const userRoutes: Routes = [    
    { path: 'user/change/password', component: PasswordComponent, pathMatch: 'prefix' },
    { path: 'user', component: UserComponent }
];

@NgModule({
  declarations: [
    UserComponent,
    PasswordComponent, 
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forChild(userRoutes)
  ],
  bootstrap: [
    UserComponent
  ],
  exports: [
    UserComponent  
  ],
})

export class UserModule {}