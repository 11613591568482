import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service'

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./user.component.css', '../shared/global.css']
})
export class PasswordComponent implements OnInit {
  password: FormControl = new FormControl(null, Validators.required);
  confirmPassword: FormControl = new FormControl(null, Validators.required);
  changePasswordGroup: FormGroup;
  token: any;

  constructor(@Inject(FormBuilder) formBuilder:FormBuilder, 
              private http: HttpClient,
              private router: Router, 
              private activatedRoute: ActivatedRoute) { 
    this.changePasswordGroup = formBuilder.group({      
      password_in: this.password      
    });
  }

  updateChangePasswordForm() {
    this.changePasswordGroup.updateValueAndValidity();
  }

  change() {
    if(this.changePasswordGroup.valid) {
      this.http.post('/user/change/password?token=' + this.token, this.changePasswordGroup.value).subscribe(
        user => {
          this.router.navigate(['login']);
        });        
    }
  }

  cancel() {
    this.router.navigate(['branch']);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = params['token'];
    });
  }
}
