import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css', '../shared/global.css']
})

export class BoardComponent implements OnInit, OnDestroy {
  board: FormControl = new FormControl(null, Validators.required);
  board_old: FormControl = new FormControl();
  board_new: FormControl = new FormControl(null, Validators.required);
  private taskChangeSubscription_: Subscription;
  //addBranchGroup: FormGroup;
  addBoardGroup: FormGroup;
  editBoardGroup: FormGroup;
  boards : any;
  showAddFlag: Boolean = false;
  showEditFlag: Boolean = false;
  showDeleteFlag: Boolean = false;
  editIndex: any = -1;
  constructor(@Inject(FormBuilder) formBuilder:FormBuilder, 
    private http: HttpClient,
    private cache: Cache) {
      this.addBoardGroup = formBuilder.group({      
          board_in: this.board,           
      });

      
      this.editBoardGroup = formBuilder.group({  
        board_old: this.board_old,     
        board_new: this.board_new,            
      });

      this.taskChangeSubscription_ = cache.taskChange.subscribe(
        task => {
          if(task == 'edit') { 
            this.showEditFlag = !this.showEditFlag;
            this.showDeleteFlag = false;
            this.editIndex = -1;   
          }        
      });
     }

  showAddForm() {
    this.showAddFlag = !this.showAddFlag;
    this.editIndex = -1;     
    this.board.setValue('');
  }

  add() {  
    if(this.addBoardGroup.valid) {
      this.http.post('/board/add', this.addBoardGroup.value).subscribe(
        board => { 
          //this.cache.setBoardList(board); 
          this.boards = board;          
          this.cancel();           
        });  
    }  
  }

  cancel() {
    this.showAddFlag = false;   
    this.showEditFlag = false;   
    this.showDeleteFlag = false;
    this.editIndex = -1;  
  }

  showEditForm(i) {    
    this.editIndex = i;    
    this.showEditFlag = false;
    this.showAddFlag = false;
    this.editBoardGroup.patchValue({
      board_old: this.boards[i].bnm});
      this.board_new.setValue('');
  }

  editForm(i) {   
    if(this.editBoardGroup.valid) {
      this.http.post('/board/edit', this.editBoardGroup.value).subscribe(
        board => { 
          this.boards = board;
          this.cancel();         
        });        
    }
  }

  remove(i) {        
    if(this.showDeleteFlag) {      
      this.http.post('/board/remove', {board_in: this.boards[i].bnm}).subscribe(
        board => { 
          this.boards = board;
          this.cancel();           
        });
    } else {
      this.showDeleteFlag = true;
      this.editIndex = i;
    };
  }

  ngOnInit() {
    if(!this.boards) {
      this.http.post('/board/list', {}).subscribe(board => {
        this.boards = board;
      });
    }
  }

  ngOnDestroy() {     
    this.taskChangeSubscription_.unsubscribe();
  }
}
