import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BoardComponent } from './board.component';
import { SharedModule } from '../shared/shared.module';

const boardRoutes: Routes = [
    {
    path: 'board',
    component: BoardComponent
    }
];

@NgModule({
  declarations: [
    BoardComponent   
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forChild(boardRoutes)
  ],
  bootstrap: [
    BoardComponent
  ],
  exports: [
    BoardComponent  
  ],
})

export class BoardModule {}