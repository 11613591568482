import { Component, Input, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { FrameComponent } from './frame.component';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['../shared/global.css']
})

export class CanvasComponent extends FrameComponent implements OnInit, OnDestroy {
  @Input() set frames_(value: any) {
    this.frames = value;
    this.frmUpdate();
  }

  @Input() set showFrames_(value: Boolean) {
    this.showFramesFlag = value;
    try { this.showFrames(); } catch(e) {};
  } 

  private imageChangeSubscription_: Subscription;  
  private framesChangeSubscription_: Subscription;
  sign: any;
  branch: any;
  serialNo: any;
  images: any;  
  showFramesFlag: Boolean = false;
  frms: any;  
  fgColor: number = 3;
  auxData: any;
  error: Boolean;
  errorMessage: string;
  transientLayerEnable: Boolean = false;
  pixelDrawFlag: Boolean = false;
  drawLineFlag: Boolean = false;
  drawSquareFlag: Boolean = false;
  fillSquareFlag: Boolean = false;
  drawCircleFlag: Boolean = false;
  fillCircleFlag: Boolean = false;
  pasteLayerFlag: Boolean = false;
  imageMapUpdatedFlag: Boolean = false;
  copyPasteObj: any = {valid: false, canvas: '', xLen: 0, yLen: 0};

  constructor(private activatedRoute: ActivatedRoute,
              public router: Router,
              public cache: Cache,
              public http: HttpClient) { 
    super();
    this.offsetFlag = true;    
    this.activatedRoute.params.subscribe(params => {      
      this.type = params['type'];
      this.sign = params['sign'];
      this.branch = params['branch'];
      this.serialNo = params['serialNo'];
      this.page = 1; 
      if(this.type == 2) this.imageChangeSubscription_ = cache.imageType2Change.subscribe(image => { this.images = image; });
      else if(this.type == 3 || this.type == 4) this.imageChangeSubscription_ = cache.imageType4Change.subscribe(image => { this.images = image; });
      else if(this.type == 5) this.imageChangeSubscription_ = cache.imageType5Change.subscribe(image => { this.images = image; });
      else if(this.type == 6) this.imageChangeSubscription_ = cache.imageType6Change.subscribe(image => { this.images = image; });
      this.frames = []; 
      this.auxData = [];
      var end = 12;
      if(this.type == 5 || this.type == 6) end = 6;   
      for(var i = 0; i < end; ++i) { 
        this.frames.push({mod: 4, disp: 15, blank: 10, ord: i, scr: ""}); 
        this.auxData.push({radar: ""});
      } 
      this.setSignParam();
      this.imageMap = [];
      for(var j = 0; j < this.ySize; ++j) {
        for(var i = 0; i < this.xSize; ++i) {
          this.imageMap.push(0);
          this.imageMap.push(0);
          this.imageMap.push(0);
        }        
      }          
    });       
  }

  /*click(event: MouseEvent) {
    if(this.controlShowFlag) {
      //console.log('x:' + event.clientX + ' y: ' + event.clientY + ' sx: ' + event.screenX + ' sy: ' + event.screenY);
     // console.log('w: ' + this.width + ' h: ' + this.height + ' p: ' + this.xPitch); 
    }   
  };*/

  frmUpdate() {
    this.frms = [];
    if(this.framesComponents) {
      this.frms = this.framesComponents.toArray();  
      var j = 1;
      this.frms.forEach(f => {
        f.frames = this.frames;
        f.page = j;
        f.type = this.type;
        ++j;      
      })
    }
  }

  uuSubEncode(charCode) {
    var tmp = String.fromCharCode(charCode + 32);
    if(charCode == 0) tmp = String.fromCharCode(96);
    else if(charCode == "\\") tmp =  "\\\\";
    else if(charCode == "\"") tmp =  "\"";
    return tmp;
  }

  uuBlockEncode(start, no) {
    var str = "";
    var ch = [0, 0, 0, 0];
    for(var i = 0; i < no; ++i) {
      if(this.fiveColor) {        
        ch[0] = (this.imageMap[start + i * 6] * 4) + 
                (this.imageMap[start + 1 + i * 6] >> 2);
        ch[1] = ((this.imageMap[start + 1 + i * 6] % 4) << 4) + 
                (this.imageMap[start + 2 + i * 6]);
        ch[2] = (this.imageMap[start + 3 + i * 6] << 2) + 
                (this.imageMap[start + 4 + i * 6] / 4);
        ch[3] = ((this.imageMap[start + 4 + i * 6] % 4) << 4) + 
                (this.imageMap[start + 5 + i * 6]);
      } else {
        ch[0] = (this.imageMap[start + 7 + (i * 24)] << 5) + 
                (this.imageMap[start + 6 + (i * 24)] << 4) + 
                (this.imageMap[start + 5 + (i * 24)] << 3) + 
                (this.imageMap[start + 4 + (i * 24)] << 2) + 
                (this.imageMap[start + 3 + (i * 24)] << 1) + 
                (this.imageMap[start + 2 + (i * 24)]);      
        ch[1] = (this.imageMap[start + 1 + (i * 24)] << 5) + 
                (this.imageMap[start + 0 + (i * 24)] << 4) + 
                (this.imageMap[start + 15 + (i * 24)] << 3) + 
                (this.imageMap[start + 14 + (i * 24)] << 2) + 
                (this.imageMap[start + 13 + (i * 24)] << 1) + 
                (this.imageMap[start + 12 + (i * 24)]);
        ch[2] = (this.imageMap[start + 11 + (i * 24)] << 5) + 
                (this.imageMap[start + 10 + (i * 24)] << 4) + 
                (this.imageMap[start + 9 + (i * 24)] << 3) + 
                (this.imageMap[start + 8 + (i * 24)] << 2) + 
                (this.imageMap[start + 23 + (i * 24)] << 1) + 
                (this.imageMap[start + 22 + (i * 24)]);
        ch[3] = (this.imageMap[start + 21 + (i * 24)] << 5) + 
                (this.imageMap[start + 20 + (i * 24)] << 4) + 
                (this.imageMap[start + 19 + (i * 24)] << 3) + 
                (this.imageMap[start + 18 + (i * 24)] << 2) + 
                (this.imageMap[start + 17 + (i * 24)] << 1) + 
                (this.imageMap[start + 16 + (i * 24)]);              
      }
      for(var j = 0; j < 4; ++j) {
        str = str.concat(this.uuSubEncode(ch[j]));
      }            
    }    
    return str;
  }

  uuEncode() {    
    var valid = false;
    for(var i = 0; i < this.imageMap.length; ++i) {
      if(this.imageMap[i] != 0) {
        valid = true;
        break;
      }
    }
    if(!valid) return "";
    var str = "M"; 
    if(this.type == 4) {                   
      str = str.concat(this.uuBlockEncode(0, 60));
      str = str.concat("\r\nM");
      str = str.concat(this.uuBlockEncode(60 * 6, 60));
      str = str.concat("\r\nM");
      str = str.concat(this.uuBlockEncode(120 * 6, 60));
      str = str.concat("\r\nB");
      str = str.concat(this.uuBlockEncode(180 * 6, 44));
      str = str.concat("\r\n");
      return str;
    } else {        
        var ratio = 15;
        var rest = 12;       
        if(this.type == 5) {            
          ratio = 25;
          rest = 14;         
        } else if(this.type == 6) {           
          ratio = 30;
          rest = 14;
          for(var i = 0; i < 40; ++i) str += String.fromCharCode(96);         
        }             
        str = str.concat(this.uuBlockEncode(0, ratio));
        str = str.concat("\r\nM");
        str = str.concat(this.uuBlockEncode(ratio * 24, ratio));
        str = str.concat("\r\nM");
        str = str.concat(this.uuBlockEncode(ratio * 48, ratio));
        str = str.concat("\r\nB");
        str = str.concat(this.uuBlockEncode(ratio * 72, rest));
        str = str.concat("\r\n");
        return str;          
    }
  } 

  setColor(byte, index) {
    var c = [byte >> 4, byte % 16];
    for(var i = 0; i < 2; ++i) {
      if(c[i] == 0) this.imageMap[index + i] = 0;
      else {
        if(this.fiveColor) {
          this.imageMap[index + i] = (c[i] & 0x07);
        } else {
          this.imageMap[index + i] = 1;
        }  
      }
    }
  }

  imageSelect(i) {
    var bmp = atob((this.images[i].fle as string).split(',')[1]);
    this.renderImage(bmp);
  }

  analyzeBMP(bmp) {
    var ret = { err: true, width: 0, height: 0, offset: 0 };
    if(bmp.charAt(1) == 'M' && 
    bmp.charCodeAt(12) == 0 && bmp.charCodeAt(13) == 0 && 
    bmp.charCodeAt(20) == 0 && bmp.charCodeAt(21) == 0 && 
    bmp.charCodeAt(23) == 0 && bmp.charCodeAt(24) == 0 && bmp.charCodeAt(25) == 0) {                
      ret.offset = (bmp.charCodeAt(11) << 8) + bmp.charCodeAt(10);
      ret.width = (bmp.charCodeAt(19) << 8) + bmp.charCodeAt(18);
      ret.height = bmp.charCodeAt(22);
      ret.err = false;
    }
    return ret;
  }
  
  renderImage(bmp) {
    var check = this.analyzeBMP(bmp);
    if(!check.err) {                
      var offset = check.offset;
      var width = check.width;
      var height = check.height;
      width /= 2;
      var adj = width % 4;
      for(var j = 0; j < height; j++){          
          for(var k = 0;  k < width; k++) {
            var b = bmp.charCodeAt(offset++);               
            var index = (width * 2 * (height - j - 1)) + (2 * k);
            this.setColor(b, index);           
          }
          offset += adj;
      }                                   
    }
    this.updatePage();
    this.imageMapUpdatedFlag = true;
    this.painting.selection = false;
  }

  validateFrames() {
    this.error = false;
    for(var i = 0; i < this.frames.length; ++i) {
      var s = this.frames[i].scr;
      if(this.frames[i].mod == 3) {
        if(this.auxData[i].radar == '') {
          this.error = true; 
          this.errorMessage = 'Error: Invalid RADAR String! Page: ' + i + 1 + ' ~ 3L * 8C with ### ';   
        }
      }      
    }
    return this.error;
  }

  updatePage() {
    this.frames[this.page - 1].scr = this.uuEncode();
    this.frmUpdate();
    this.validateFrames();
  }

  resetPage() {
    this.frames[this.page - 1].scr = "";    
    this.clearImageMap();
    this.validateFrames();  
  }
  
  clear() {      
    this.auxData[this.page - 1].radar = "";   
    this.resetPage();
  }

  showFrames() {
    this.showFramesFlag = !this.showFramesFlag;
    if(this.showFramesFlag) {
      this.framesChangeSubscription_ = this.framesComponents.changes.subscribe(() => {
        this.frmUpdate();
      });
    }     
  }
   
  resetAllDrawFlags() {
    this.pixelDrawFlag = false;
    this.drawLineFlag = false;
    this.drawSquareFlag = false;
    this.fillSquareFlag = false;
    this.drawCircleFlag = false;
    this.fillCircleFlag = false;
    this.transientLayerEnable = false;
    this.pasteLayerFlag = false;
    this.painting.selection = false;
    this.painting.firstPositionDone = false;
  }

  selectLayerPressed() {
    if(this.painting.selection) {      
      this.resetAllDrawFlags();
    } else {
      this.resetAllDrawFlags();
      this.painting.selection = true;      
      this.painting.x1 = this.xSize;
      this.painting.y1 = this.ySize;
      this.painting.x2 = this.xSize;
      this.painting.y2 = this.ySize;
    }
  }

  moveCopyCache(ixy, layer, end) {     
    if(end) {
      this.resetAllDrawFlags();
    } else {
      this.painting.selection = true; 
      this.painting.x1 = ixy.x;   
      this.painting.y1 = ixy.y;
    }
    
    for(var i = 0; i < this.copyPasteObj.xLen; ++i) {
      for(var j = 0; j < this.copyPasteObj.yLen; ++j) {
        var ind = i + (j * this.xSize); 
        var x = ixy.x + i;
        var y = ixy.y + j;
        if(x >= 0 && y >= 0 && x < this.xSize && y < this.ySize) {                
          layer[x + (y * this.xSize)] = this.copyPasteObj.canvas[ind];
          this.painting.x2 = x;   
          this.painting.y2 = y;
        }              
      }
    }      
  }

  drawPixelPressed() {
    if(this.pixelDrawFlag) {
      this.resetAllDrawFlags();
    } else { 
      this.resetAllDrawFlags();
      this.pixelDrawFlag = true;      
    }
  }

  putPixel(layer) {    
    var index = this.painting.x2 + this.xSize * this.painting.y2;
    if(layer[index] != 0) 
      layer[index] = 0;
    else {
      layer[index] = this.fiveColor ? this.fgColor: 1;      
    }
  }

  drawLinePressed() {
    if(this.drawLineFlag) {
      this.resetAllDrawFlags();
    } else {
      this.resetAllDrawFlags();
      this.drawLineFlag = true;
    }
  }

  drawLine(layer) {
    if(this.painting.x1 < 0) this.painting.x1 = 0;
    if(this.painting.y1 < 0) this.painting.y1 = 0;
    if(this.painting.x2 < 0) this.painting.x2 = 0;
    if(this.painting.y2 < 0) this.painting.y2 = 0;
    var iDxDy = this.findSelectArea();
    var ixy = this.painting;    
    //this.drawLineHelper1(layer, ixy.x1, ixy.y1, ixy.x2, ixy.y2);  
    var dxSign = -1;
    if(ixy.x2 > ixy.x1) dxSign = 1;
    var dySign = -1;
    if(ixy.y2 > ixy.y1) dySign = 1;  
    var i = 0, dif = 1;
    if(iDxDy.y2 > iDxDy.x2) {   
      if(iDxDy.x2 > 0) {
        dif = Math.floor(iDxDy.y2 / iDxDy.x2);    
        do {
          var offset = dxSign * Math.floor(i / dif);
          var y = this.xSize * (ixy.y1 + (dySign * i)) + offset + ixy.x1; 
          layer[y] = this.fiveColor ? this.fgColor: 1;   
          ++i;  
        } while(iDxDy.y2--);
      } else {
        do {
          var y = this.xSize * (ixy.y1 + (dySign * i)) + ixy.x1; 
          layer[y] = this.fiveColor ? this.fgColor: 1;   
          ++i; 
        } while(iDxDy.y2--);
      }
    } else {
      if(iDxDy.y2 > 0) {
        dif = Math.floor(iDxDy.x2 / iDxDy.y2);  
        do {
          var offset = dySign * Math.floor(i / dif);
          var y = this.xSize * (ixy.y1 + offset) + (dxSign * i) + ixy.x1; 
          layer[y] = this.fiveColor ? this.fgColor: 1;   
          ++i;  
        } while(iDxDy.x2--);
      } else {
        do {
          var y = this.xSize * (ixy.y1) + (dxSign * i) + ixy.x1; 
          layer[y] = this.fiveColor ? this.fgColor: 1;   
          ++i; 
        } while(iDxDy.x2--);
      }        
    }
  }

  drawCirclePressed() {
    if(this.drawCircleFlag) {
      this.resetAllDrawFlags();
    } else { 
      this.resetAllDrawFlags();
      this.drawCircleFlag = true;      
    }
  }

  drawCircle(layer) {
    var ixy = this.findSelectArea();
    var rad = ixy.x2 * ixy.x2 + ixy.y2 * ixy.y2;
    rad = Math.sqrt(Number(rad));
    for(var i = 0; i < 360; i += 1) {
      var x = ixy.x1 + Math.floor(rad * Math.cos((Math.PI * i) / 180));
      var y = ixy.y1 + Math.floor(rad * Math.sin((Math.PI * i) / 180));
      if(x >= 0 && y >= 0 && x < this.xSize && y < this.ySize) {
        var ind = x + this.xSize * y;
        layer[ind] = this.fiveColor ? this.fgColor: 1;
      }
    }
  }
  fillCirclePressed() {
    if(this.fillCircleFlag) {
      this.resetAllDrawFlags();
    } else { 
      this.resetAllDrawFlags();
      this.fillCircleFlag = true;      
    }
  }

  fillCircle(layer) {
    var ixy = this.findSelectArea();
    var rad = ixy.x2 * ixy.x2 + ixy.y2 * ixy.y2;
    rad = Math.sqrt(Number(rad));
    for(var i = 0; i < 180; i += 1) {
      var rx = Math.floor(rad * Math.cos((Math.PI * i) / 180));      
      var ry = Math.floor(rad * Math.sin((Math.PI * i) / 180));  
      var x2 = ixy.x1 + rx;
      if(x2 > this.xSize) x2 = this.xSize;
      var x1 = ixy.x1 - rx;
      if(x1 < 0) x1 = 0;
      for(var j = x1; j < x2; ++j) {
        var ind = j + this.xSize * (ixy.y1 + ry);
        layer[ind] = this.fiveColor ? this.fgColor: 1;
        if(ixy.y1 >= ry) {
          ind = j + this.xSize * (ixy.y1 - ry);
          layer[ind] = this.fiveColor ? this.fgColor: 1;
        }
      }
    }
  }

  drawSquarePressed() {
    if(this.drawSquareFlag) {
      this.resetAllDrawFlags();
    } else {
      this.resetAllDrawFlags();
      this.drawSquareFlag = true;
    }
  }

  drawSquare(layer) {
    var ixy = this.findSelectArea();
    for(var i = 0; i < ixy.x2; ++i) {
      var ind = i + ixy.x1 + this.xSize * ixy.y1;
      layer[ind] = this.fiveColor ? this.fgColor: 1;
      ind = i + ixy.x1 + this.xSize * (ixy.y1 + ixy.y2);
      layer[ind] = this.fiveColor ? this.fgColor: 1;
    }  
    for(var i = 0; i < ixy.y2 + 1; ++i) {
      var ind = ixy.x1 + this.xSize * (ixy.y1 + i);
      layer[ind] = this.fiveColor ? this.fgColor: 1;
      ind = ixy.x1 + ixy.x2 + this.xSize * (ixy.y1 + i);
      layer[ind] = this.fiveColor ? this.fgColor: 1;
    }      
  }

  fillSquarePressed() {
    if(this.fillSquareFlag) {
      this.resetAllDrawFlags();
    } else {
      this.resetAllDrawFlags();
      this.fillSquareFlag = true;
    }
  }

  fillSquare(layer) {    
    var ixy = this.findSelectArea();
    for(var j = 0; j < ixy.y2; ++j) {
      for(var i = 0; i < ixy.x2 + 1; ++i) {
        var ind = i + ixy.x1 + this.xSize * (ixy.y1 + j);
        layer[ind] = this.fiveColor ? this.fgColor: 1;
      }
    }  
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if(this.imageChangeSubscription_) this.imageChangeSubscription_.unsubscribe(); 
    if(this.framesChangeSubscription_) this.framesChangeSubscription_.unsubscribe();    
  }
}
