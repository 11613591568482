import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css', '../shared/global.css']
})
export class ImageComponent implements OnInit {  
  images: any = [];
  list: any;
  loading: Boolean = false;
  showDeleteFlag: Boolean = false;

  constructor(private http: HttpClient) { }

  loadFiles(event) {
    this.images = [];
    this.list = [];
    var promise = [];
    let files = [].slice.call(event.target.files); 
    if(event.target.files && event.target.files.length > 0) {      
      for(var i = 0; i < event.target.files.length; ++i) {        
        promise.push(new Promise((resolve, reject) => {
          let fileName = event.target.files[i].name;
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
          reader.onload = () => {
            var res = reader.result;
            var bmp = atob((reader.result as string).split(',')[1]);            
            if(bmp.charAt(1) == 'M' && 
              bmp.charCodeAt(12) == 0 && bmp.charCodeAt(13) == 0 && 
              bmp.charCodeAt(20) == 0 && bmp.charCodeAt(21) == 0 && 
              bmp.charCodeAt(23) == 0 && bmp.charCodeAt(24) == 0 && bmp.charCodeAt(25) == 0) {                
                var width = (bmp.charCodeAt(19) << 8) + bmp.charCodeAt(18);
                var height = bmp.charCodeAt(22);
                var prefix = 'S';
                var type = '16';
                if(width == 48 && height == 28) type = '3';
                else if(width == 52 && height == 26) type = '2';
                else if(width == 60 && height == 35) type = '5';
                else if(width == 60 && height == 32) type = '6';
                else prefix = 'F';                
                this.images.push({
                  name_in: prefix + width + 'X' + height + ((fileName as string).split('.')[0]),
                  type_in: type,              
                  file_in: res
                });  
              }          
            resolve();
          }
        }));      
      }
    }
    return promise;
  }

  onFileChange(event) {
    var promise = this.loadFiles(event);
    Promise.all(promise).then((values) => {});
  }

  onSubmit() {
    this.loading = true;
    var promise = [];
    for(var i = 0; i < this.images.length; ++i) {
      promise.push(new Promise((resolve, reject) => {
        this.http.post('/image/add', this.images[i]).subscribe(image => 
          { 
            this.list = image;            
            resolve();           
          });              
      }));
    }

    Promise.all(promise).then((values) => {
      this.loading = false;
      this.images = [];
    });
  }

  edit() {
    this.showDeleteFlag = !this.showDeleteFlag;
  }

  remove(i) {        
    if(this.showDeleteFlag) {      
      this.http.post('/image/remove', {name_in: this.list[i].inm}).subscribe(
        image => {           
          this.list = image; 
        }, error => { this.list = []; });
    } 
  }

  ngOnInit() { 
    if(!this.list) {
      this.http.post('/image/list', {}).subscribe(image => {
        this.list = image;
      });
    }
  }
}
