import { Component, Input, ViewChildren, QueryList, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html'  
})

export class FrameComponent implements OnInit, OnDestroy {
  @ViewChildren(FrameComponent) framesComponents: QueryList<FrameComponent>; 
  @ViewChild('myCanvas', {static: true}) canvasRef: ElementRef;  
  @Input() type: any;
  @Input() frames: any;
  @Input() page: number;
  @Input() controlShowFlag: Boolean;
  @Input() maxWidth: number;
  width: number;
  height: number;
  xPitch: number;
  xSize: number = 48;
  ySize: number = 28;
  fiveColor: boolean = false;
  color: any = ['#000000', '#FF0000','#FFFF00','#FFFF00', '#0000FF', '#000000', '#00FF00', '#FFFFFF'];
  canvasOffset: number = 0;
  offsetFlag: Boolean = false;
  modes: any = [{mode: 3, desc: 'RADAR Speed Mode'}, {mode: 2, desc: 'RADAR Triggered Mode'}, {mode: 4, desc: 'Image/Text Mode'}];
  framePages: any;
  imageMap: any;
  scrollXOffset: number = 0;
  scrollYOffset: number = 0;

  private _painting: any = { enable: false, selection: false, firstPositionDone: false, x1: 0, y1: 0, x2: 0, y2: 0 };
  public get painting(): any {
    return this._painting;
  }
  public set painting(value: any) {
    this._painting = value;
  }
  
  constructor() {
    this.imageMap = [];
    for(var j = 0; j < this.ySize; ++j) {
      for(var i = 0; i < this.xSize; ++i) {
        this.imageMap.push(0);
        this.imageMap.push(0);
        this.imageMap.push(0);
      }        
    } 
  }
  
  click(event) {};

  modeDesc() {    
    var mode = this.frames[this.page - 1].mod;
    if(mode == 4 || mode == 5) return this.modes[2].desc;
    else if(mode == 2) return this.modes[1].desc;
    else if(mode == 1 || mode == 3) return this.modes[0].desc;
    else if(mode == 6) return 'Sign Off';
    else if(mode == 7) return 'No Scheduling';
  }

  setPagesNo() {
    this.framePages = [];
    for(var i = 0; i < this.frames.length; ++i) {        
      if(this.frames[i].scr == '') break;
      this.framePages.push(i + 1);    
    }
    if(this.framePages.length < 1) {
      this.framePages.push(1);       
    }
  }

  setSignParam() {
    if(this.type == 4) {
      this.fiveColor = true;
    } else if(this.type == 2) {
      this.xSize = 52;
      this.ySize = 26;
    } else if(this.type == 5) {
      this.xSize = 60;
      this.ySize = 35;
    } else if(this.type == 6) {
      this.xSize = 60;
      this.ySize = 32;
    }
    this.setPagesNo();    
  }

  clearImageMap() {
    for(var i = 0; i < this.imageMap.length; ++i) this.imageMap[i] = 0;
  }

  decodeUUStr(index) {
    var buffer = [];    
    this.clearImageMap();
    var scr = this.frames[index].scr;     
    var start = 1;    
    if(this.type == 6) start = 41; // 1508E incompatibility!
    for(var i = start; i < scr.length; ++i) {
      var ch = [0, 0, 0, 0];
      ch[0] = scr.charCodeAt(i);
      if(ch[0] == 13) i += 2;
      else {
        ch[0] = (ch[0] - 32) & 0x3F;        
        for(var j = 1; j < 4; ++j) {
          ch[j] = (scr.charCodeAt(++i) - 32) & 0x3F;              
        }
        if(this.fiveColor) {
          buffer.push(ch[0] >> 2);
          buffer.push(((ch[0] & 0x3) << 2) + (ch[1] >> 4));        
          buffer.push(ch[1] & 0x0F);
          buffer.push(ch[2] >> 2);
          buffer.push(((ch[2] & 0x3) << 2) + (ch[3] >> 4));  
          buffer.push(ch[3] & 0x0F);  
        } else {
          var scan = [[0x10, 0x21, 1], [0x01, 0x21, 0], [0x04, 0x21, 2], 
                      [0x01, 0x09, 1], [0x01, 0x21, 3], [0x01, 0x03, 2]];

          for(var k = 0; k < scan.length; ++k) {
            for(var j = scan[k][0]; j < scan[k][1]; j = j << 1) {
              if(ch[scan[k][2]] & j) buffer.push(2);
              else buffer.push(0);
            }
          }
        }     
      }      
    }    
    return buffer;
  }
  
  mouseXY2DisplayXY(event) {
    var pitch = Math.floor(this.xPitch);
    var offsetPitch = Math.floor(pitch / 2);
    var x = event.clientX - this.canvasOffset + this.scrollXOffset - offsetPitch;
    x = Math.floor(x / pitch);
    if(x >= this.xSize) return;
    var y = Math.floor((event.clientY + this.scrollYOffset - offsetPitch) / pitch);
    var index = x + this.xSize * y;
    //console.log("x1: " + this.painting.x1 + " x2: " + this.painting.x2 + "y1: " + this.painting.y1 + " y2: " + this.painting.y2 + " f: " + this.painting.selection);
    return {index: index, x: x, y: y};
  }

  findSelectArea() {
    var x1 = this.painting.x1;
    var x2 = this.painting.x2;
    var y1 = this.painting.y1;
    var y2 = this.painting.y2;
    if(x1 < 0) x1 = 0;
    if(x2 < 0) x2 = 0;
    if(y1 < 0) y1 = 0;
    if(y2 < 0) y2 = 0; 
    var tmp;
    if(x1 > x2) {
      tmp = x2;
      x2 = x1;
      x1 = tmp;
    }
    if(y1 > y2) {
      tmp = y2;
      y2 = y1;
      y1 = tmp;
    }
    x2 = x2 - x1;       
    y2 = y2 - y1;      
    if(x1 > this.xSize) x1 = this.xSize;
    if(x2 > this.xSize) x2 = this.xSize;
    if(y1 > this.xSize) y1 = this.ySize;
    if(y2 > this.xSize) y2 = this.ySize; 
    return {x1: x1, x2: x2, y1: y1, y2: y2};
  }
  
  gridAndSelect(ctx) {
    if(this.page == 0) return;
    if(this.painting.enable) {
      ctx.beginPath();
      var offset = this.xPitch / 2;
      if(this.painting.selection) {
        ctx.fillStyle = "grey";
        var sel = this.findSelectArea();
        ctx.fillRect(this.canvasOffset + offset + sel.x1 * this.xPitch, offset + sel.y1 * this.xPitch, (sel.x2 + 1) * this.xPitch, (sel.y2 + 1) * this.xPitch);
      } 
      ctx.strokeStyle = "darkgrey";   
      
      for(let i = 0; i < this.xSize + 1; ++i) {
        ctx.moveTo(this.canvasOffset + i * this.xPitch + offset, offset);  
        ctx.lineTo(this.canvasOffset + i * this.xPitch + offset, this.height - offset);
        ctx.stroke();   
      }   
      for(let j = 0; j < this.ySize + 1; ++j) {
        ctx.moveTo(this.canvasOffset + offset, j * this.xPitch + offset);  
        ctx.lineTo(this.canvasOffset + this.xSize * this.xPitch + offset, j * this.xPitch + offset);
        ctx.stroke();  
      }     
      ctx.fill(); 
    }    
  }

  render(index) {    
    this.setSignParam();
    let ctx: CanvasRenderingContext2D =  this.canvasRef.nativeElement.getContext('2d');
    var element = ctx.canvas.offsetParent;
    this.width = screen.availWidth > this.maxWidth ? this.maxWidth : (screen.availWidth * 1.0);
    this.xPitch = Math.floor(this.width / (this.xSize + 1));
    this.width +=  this.xPitch;
    this.height = this.xPitch * this.ySize  + 1 * this.xPitch; 
    if(this.controlShowFlag) this.canvasOffset = (screen.availWidth > this.width && this.offsetFlag) ? ((screen.availWidth - this.width) / 2) : 0; 
    else this.canvasOffset = 10;
    ctx.canvas.width = this.width + this.canvasOffset;
    ctx.canvas.height = this.height;             
    ctx.fillStyle = this.color[0];
    ctx.fillRect(this.canvasOffset, 0, this.width + this.canvasOffset, this.height);    
    var buffer = this.decodeUUStr(index);
    // we check the validity of buffer size below!!
    this.gridAndSelect(ctx);
    if(buffer.length < (this.xSize * this.ySize)) return;    
    var boundary = 2;
    if(this.xPitch < 4) boundary = 0;
    for(let c = 1; c < 8 && index < 128; ++c) {    
      ctx.fillStyle = this.color[c];
      ctx.beginPath();      
      for(let i = 1; i < this.xSize + 1; ++i) {
        for(let j = 1; j < this.ySize + 1; ++j) {        
          var ind = (j - 1) * this.xSize + i - 1;          
          if(buffer[ind] == c) {
            if(this.fiveColor) this.imageMap[ind] = c;
            else this.imageMap[ind] = 1;
            ctx.moveTo(i * this.xPitch + this.canvasOffset, j * this.xPitch);        
            ctx.arc(i * this.xPitch + this.canvasOffset, j * this.xPitch, (this.xPitch - boundary) / 2, 0, 2 * Math.PI);
          }          
        }
      }
      ctx.fill();   
    }        
  }

  findNextFrameIndex(index) {
    var maxLoop = 12;
    var index_ = index ;
    do { // jump over different RADAR modes
      if(++index_ == this.frames.length || this.frames[index_].scr == "" || this.frames[0].scr == "") { index_ = 0; break; } 
      if(this.frames[index_].mod == 4 || this.frames[index_].mod == 5 || index_ == index) break;
    } while(--maxLoop > 0);
    return index_; 
  }

  tick: any;
  scan() {
    var index = 0;    
    var disp = false;
    var cnt = 15;  
    this.tick = setInterval(() => {         
      if(cnt != 0) --cnt;  
      //if(cnt > 99) cnt = 99; // just for safety!!
      try {          
        if(this.page == 0) {  
          if(cnt == 0) {
            if(disp) { 
              var mode = this.frames[index].mod;
              disp = false; 
              cnt = this.frames[index].blank;            
              if(cnt != 0 || mode == 6 || mode == 7) {               
                try { this.render(255); }  catch(e) {};   
                if(mode == 6 || mode == 7) cnt = 50;
              }
              index = this.findNextFrameIndex(index);                                               
            }   
          }   
          if(cnt == 0) {       
            if(!disp) {             
              var mode = this.frames[index].mod;
              try { if(mode == 4 || mode == 5) this.render(index); } catch(e) {};   
              cnt = this.frames[index].disp; 
              if(cnt != 0 || this.frames[index].scr == "") {
                disp = true;
              } else cnt = 50;                                            
            }         
          }                 
        } else { // no animation!
          this.render(this.page - 1);
          cnt = 50;
        }                    
      } catch(e) { cnt = 50; index = 0; } // Unknown error caught here!                  
    }, 100);   
  }
  
  ngOnInit() {  
    this.page = 0;  
    this.scan();
  }

  ngOnDestroy() {
    clearInterval(this.tick);    
  }
}