import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.css', '../shared/global.css']
})

export class ArchiveComponent implements OnInit , OnDestroy {
  private userChangeSubscription_: Subscription;
  interval: FormControl = new FormControl(null, Validators.required);
  removeArchiveGroup: FormGroup;
  user: any;
  archives: any;
  showRemoveFlag: Boolean = false;

  constructor(
    @Inject(FormBuilder) formBuilder:FormBuilder, 
    private http: HttpClient, 
    private cache: Cache) {
      this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.user = user; });
      this.cancel();
      this.removeArchiveGroup = formBuilder.group({      
        interval_in: this.interval              
    });
  }  

  cancel() { 
    this.showRemoveFlag = false; 
  }

  remove() {  
    this.showRemoveFlag = false;    
    if(this.removeArchiveGroup.valid) {
      this.http.post('/archive/remove/all', this.removeArchiveGroup.value).subscribe(
        archive => { 
          this.archives = archive;
          this.cancel();           
          (<any>window).ga('send', 'event', {
            eventCategory: 'Archive Recycle',
            eventLabel: this.user.email,
            eventAction: this.interval.value,
            eventValue: 1
          });
        }
      );  
    }  
  }
 
  ngOnInit() {
    if(!this.archives) {
      this.http.get('/archive/list').subscribe(archive => {
        this.archives = archive;
      });
    }
  }

  ngOnDestroy() {
    this.userChangeSubscription_.unsubscribe();
  }
}
