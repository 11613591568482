import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { Cache } from '../shared/cache.service';
import { LogoComponent } from './logo.component';

@NgModule({
    declarations: [ 
      ToolbarComponent,
      LogoComponent
     ],
    imports: [BrowserModule],
    exports: [ ToolbarComponent ],
    providers: [ Cache ]
  })

  export class SharedModule {}