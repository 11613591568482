import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service'

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.css', '../shared/global.css']
})
export class SignComponent implements OnInit, OnDestroy {
  branch_in: FormControl = new FormControl(null, Validators.required);
  sign_in: FormControl = new FormControl();
  editSignGroup: FormGroup;
  private branchListChangeSubscription_: Subscription;
  private taskChangeSubscription_: Subscription;
  private userChangeSubscription_: Subscription;  
  signCache: any;
  signs: any;
  branches: any;
  user: any;
  showAddFlag: Boolean = false;
  showEditFlag: Boolean = false;
  showDeleteFlag: Boolean = false;
  editIndex: any = -1;
  signFilterModel: any = '';
  branchFilterModel: any = '';

  constructor(@Inject(FormBuilder) formBuilder:FormBuilder, 
      private http: HttpClient, 
      private cache: Cache) {        

    this.editSignGroup = formBuilder.group({  
      sign_in: this.sign_in,     
      branch_new: this.branch_in,
      serial_no_in: null              
    });

    this.branchListChangeSubscription_ = cache.branchListChange.subscribe(
      branch => {         
      this.branches = branch;    
    });

    this.taskChangeSubscription_ = cache.taskChange.subscribe(
      task => {
        if(task == 'edit') { 
          this.showEditFlag = !this.showEditFlag;
          this.showDeleteFlag = false;
          this.editIndex = -1;   
          this.resetFilter();
        }           
      
    });

    this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.user = user; });
    this.cancel();
  }

  selectBranch(i, j) {
    this.signCache.list[i].bnm = this.branches[j].bnm;
  } 
 
  add(i) {  
    var sign_ = this.signCache.list[i].snm;
    this.http.post('/sign/add', {sign_in: sign_, serial_no_in: this.signCache.list[i].sno, 
                      branch_in: this.signCache.list[i].bnm}).subscribe(sign => {      
      this.removeFromCache(i);  
      this.signs = sign;          
      (<any>window).ga('send', 'event', {
        eventCategory: 'Sign Add',
        eventLabel: this.user.email,
        eventAction: sign_,
        eventValue: 1
      });
    });   
  }

  removeFromCache(i) {
    this.signCache.list.splice(i, 1);
  }

  resetCache() {
    this.http.get('/sign/register/reset').subscribe(sign => this.signCache = sign);
  }

  cancel() {
    this.showAddFlag = false;   
    this.showEditFlag = false;   
    this.showDeleteFlag = false;
    this.editIndex = -1;  
    this.resetFilter();
  }

  showEditForm(i) {    
    this.editIndex = i;    
    this.showEditFlag = false;    
    this.branch_in.setValue(this.signs[i].bnm);
    this.sign_in.setValue(this.signs[i].snm);
    this.editSignGroup.patchValue({
      sign_in: this.sign_in.value,     
      branch_new: this.branch_in.value,
      serial_no_in: this.signs[i].sno
    });
    //console.log(this.editSignGroup.value);
  }

  selectBranchFromList(j) {
    this.editSignGroup.patchValue({branch_new: this.branches[j].bnm});
  }

  updateEditForm() {
    this.editSignGroup.updateValueAndValidity();
  }

  editForm(i) {
    if(this.editSignGroup.valid) {
      this.http.post('/sign/edit', this.editSignGroup.value).subscribe(
        sign => { 
          this.signs = sign;
          this.cancel(); 
          (<any>window).ga('send', 'event', {
            eventCategory: 'Sign Edit',
            eventLabel: this.user.email,
            eventAction: this.branch_in.value,
            eventValue: 2
          });

        });        
    }
  }

  removeFromList(i) {        
    if(this.showDeleteFlag) {      
      var sign_ = this.signs[i].snm;
      this.http.post('/sign/remove', {sign_in: sign_, branch_in: this.signs[i].bnm}).subscribe(
        sign => { 
          this.signs = sign; 
          this.cancel(); 
          (<any>window).ga('send', 'event', {
            eventCategory: 'Sign Remove',
            eventLabel: this.user.email,
            eventAction: sign_,
            eventValue: 2
          });
        });
    } else {
      this.showDeleteFlag = true;
      this.editIndex = i;
    };
  }

  resetFilter() {
    this.signFilterModel = '';
    this.branchFilterModel = '';
  }

  filter(i) {
    var flag = true;
    if(this.signFilterModel != '' && !this.signs[i].snm.toLowerCase().startsWith(this.signFilterModel.toLowerCase())) flag = false
    if(this.branchFilterModel != '' && !this.signs[i].bnm.toLowerCase().startsWith(this.branchFilterModel.toLowerCase())) flag = false
    return flag;
  }

  ngOnInit() {
    if(!this.signs) {
      this.http.get('/sign/list/all', {}).subscribe(sign => {
        this.signs = sign;
      });
    }

    if(!this.branches) {
      this.http.post('/branch/list', {}).subscribe(branch => {
        this.cache.setBranchList(branch);
      });
    }

    this.http.get('/sign/register').subscribe(sign => {
      try {
        this.signCache = sign; 
        for(var i = 0; i < this.signCache.list.length; ++i) {
          this.signCache.list[i].bnm = 'Select the branch';
        }
      } catch(e) {};                
    });
  }

  ngOnDestroy() {    
    this.branchListChangeSubscription_.unsubscribe();  
    this.taskChangeSubscription_.unsubscribe();
    this.userChangeSubscription_.unsubscribe();    
  }  
}
