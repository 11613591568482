import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { DeviceService } from './device.service';

@Component({
  selector: 'app-status-bar',  
  templateUrl: './status.bar.component.html',
  styleUrls: ['../shared/global.css']
})

export class StatusBarComponent implements OnDestroy { 
    private statusChangeSubscription_: Subscription;
    status: any;
    batteryStatus: number = 1; 
    brightnessStatus: number = 1; 
    signOnline = false;
    brightness: number = 0;

    constructor(private device: DeviceService) { 
            this.statusChangeSubscription_ = device.statusChange.subscribe(
            status => {          
            this.status = status;    
            this.updateStatus();     
            //console.log(status);              
        });
    }

    
    updateStatus() {
        if(this.status.mnv > 130) {
            this.batteryStatus = 4;
        } else if(this.status.mnv > 120) {
            this.batteryStatus = 3;
        } else {
            this.batteryStatus = 1;
        }

        if(this.status.typ < 32) {            
            this.brightness = this.status.brt;
        } else {
           // console.log("Br: " + this.status.ldr)
           if(this.status.ldr > 4090) this.brightness = 64
           else this.brightness = 0
        }

        if(this.brightness > 220) {
            this.brightnessStatus = 1;
        } else if(this.brightness > 80) {
            this.brightnessStatus = 2;
        } else {
            this.brightnessStatus = 3;
        }

        this.findSignOnline();
    }

    getBrightness() {
        return Math.floor((255 - this.brightness) / 2.56) + 1;
    }
    
    getBrightnessMode() {
        if(this.status.bgm == 'A') return ' Auto ';
        else return ' Manual '
    }

    findSignOnline() {
        //var utc = '1970-1-1 12:0:0';
        //if(this.status.utc) utc = this.status.utc.replace(',', ' ');
        var signDate = new Date(this.status.utc);
        var signTS = signDate.getTime();
        var localDate = new Date();
        var offset = localDate.getTimezoneOffset();
        var localTS = localDate.getTime() + (offset * 60000);   
        //console.log('Dev: ' + this.status.snm + ' Now:' + localDate.toString()  + 'UTC: ' + this.status.utc + ' DT Sign: ' +signTS + ' L: ' + localTS);               
        if(localTS - signTS < 1000000) this.signOnline = true;
        else this.signOnline = false;
    }

    ngOnDestroy() {
        this.statusChangeSubscription_.unsubscribe(); 
    }
}