import { Component, Input, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Cache } from '../shared/cache.service';
import { CompanyComponent } from './company.component';

@Component({
  selector: 'app-add-company',
  templateUrl: './add.company.component.html',
  styleUrls: ['./company.component.css', '../shared/global.css']
})

export class AddCompanyComponent {
  @Input() user: any;
  private companyComponent: CompanyComponent;
  company: FormControl = new FormControl(null, Validators.required);
  addCompanyGroup: FormGroup;
  
  constructor(
    @Inject(FormBuilder) formBuilder:FormBuilder, 
    private http: HttpClient, 
    private cache: Cache,
    companyComponent: CompanyComponent) {
      this.companyComponent = companyComponent;
      this.addCompanyGroup = formBuilder.group({      
          company_in: this.company              
      });
  }  

  add() {  
    if(this.addCompanyGroup.valid) {
      this.http.post('/company/add', this.addCompanyGroup.value).subscribe(
        company => { 
          this.cache.setCompanyList(company); 
          this.cancel();           
          (<any>window).ga('send', 'event', {
            eventCategory: 'Company Add',
            eventLabel: this.user.email,
            eventAction: this.company.value,
            eventValue: 1
          });
        }
      );  
    }  
  }

  cancel() {
     this.companyComponent.cancel();
  }
}
