import { Component,  OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';
import { ToolbarComponent } from '../toolbar/toolbar.component';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.css', '../shared/global.css']
})

export class DisplayComponent implements OnInit, OnDestroy {
  private branchChangeSubscription_: Subscription;
  private userChangeSubscription_: Subscription;
  private taskChangeSubscription_: Subscription;
  user: any;  
  signs: any;   
  branchCache: any;
  message: any;
  show: Boolean = true;  
  techAccessFlag: Boolean = false;

  constructor(private http: HttpClient,
              private router: Router,
              private cache: Cache) { 
    this.branchChangeSubscription_ = cache.branchChange.subscribe(
      branch => {          
        try { this.branchCache = branch.name; } catch(e) {};
    });

    this.taskChangeSubscription_ = cache.taskChange.subscribe(
      task => {
        if(task == 'edit') {           
        }        
    });

    this.userChangeSubscription_ = cache.userChange.subscribe(user => { 
      this.user = user; 
      if(user.access == 'web_admin' ||
          user.access == 'service_admin' ||
          user.access == 'service_tech' ||
          user.access == 'company_admin' ||
          user.access == 'branch_admin' ||
          user.access == 'branch_tech') this.techAccessFlag = true;
    });
  }

  ngOnInit() {
    if(!this.signs) {         
      this.http.post('/sign/list', { branch_in: this.branchCache }).subscribe(sign => {
        this.signs = sign;              
      });
    } 
  }

  ngOnDestroy() {  
    this.taskChangeSubscription_.unsubscribe();
    this.userChangeSubscription_.unsubscribe(); 
    this.branchChangeSubscription_.unsubscribe();
  }
}
