import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';
import { CanvasComponent } from './canvas.component';

@Component({
  selector: 'app-concept',
  templateUrl: './concept.component.html',
  styleUrls: ['../shared/global.css']
})

export class ConceptComponent extends CanvasComponent implements OnInit, OnDestroy, AfterViewInit {
  private fontChangeSubscription_: Subscription;
  fonts: any;
  table: any = [];  
  text: any;  

  constructor(activatedRoute: ActivatedRoute,
              public router: Router,
              public cache: Cache,
              public http: HttpClient) { 
    super(activatedRoute, router, cache, http);  
    this.fontChangeSubscription_ = cache.imageType16Change.subscribe(font => { this.fonts = font; });   
    this.clearText();   
  }

  addTextLine() {
    this.text.lines.push({str: "", color: "", font: 0, x: 0, y: 0, fonts: []});
  }

  clearText() {
    this.text = {index: 0, lines: [], arrangement: [], arrIndex: 0};  
    this.addTextLine();
  }

  keyPressed(event, input) {
    if(this.controlShowFlag) {
      this.checkTable();
      switch(event.key) {
        case 'ArrowRight':        
        case 'ArrowLeft':       
        case 'Home':        
        case 'F9':        
        case 'F10':     
        case 'Delete':          
        break;
        default:
          //console.log('key: ' + event.key + ' code: ' + event.keyCode + ' a: ' + event.key.charCodeAt(0));
          if(event.keyCode == 8) {            
            this.delC();
            this.text.arrIndex = 0;
            this.printAllLines();
            this.updateRadarString();
          } else if(event.keyCode == 13) {                    
            var len = this.text.lines[this.text.index].str.length;
            this.text.arrIndex = 0;
            this.printAllLines();
            if(this.text.index < 3 && len > 0) {
              this.addTextLine(); 
              ++this.text.index;
            }            
          } else if(event.keyCode > 31){
            this.insertChar(event.target.value, input);
          }     
        break;
      }             
    }  
  }

  insertChar(ch, input) {
    var s = this.text.lines[this.text.index].str; 
    var c = this.text.lines[this.text.index].color;
    if(s.length < 13) {
      this.text.lines[this.text.index].str = input;      
      c = c.substring(0, input.length - 1);
      this.text.lines[this.text.index].color = c + String.fromCharCode(32 + this.fgColor);
      this.updateRadarString();          
    }
    this.text.arrIndex = 0;
    this.printAllLines();
  }

  updateRadarString() {
    if(this.frames[this.page - 1].mod == 3) {
      var ok = true; 
      var placeholder = false;   
      this.auxData[this.page - 1].radar = ''; 
      for(var i = 0; i < this.text.lines.length; ++i) {
        var s = this.text.lines[i].str;        
        this.auxData[this.page - 1].radar += s + '*';        
        if(s.indexOf('###') >= 0) placeholder = true;
        if(s.length > 8) { ok = false; break; }
        if(i == 3 && s != '') { ok = false; break; }        
      }      
      if(!ok || !placeholder) this.auxData[this.page - 1].radar = '';                   
    } 
  }

  delC() {
    var s = this.text.lines[this.text.index].str;            
    var c = this.text.lines[this.text.index].color;
    if(s.length > 0) {
      this.text.lines[this.text.index].str = s.substring(0, s.length - 1);
      this.text.lines[this.text.index].color =  c.substring(0, c.length - 1);
    } else { 
      if(this.text.index > 0) {
        this.text.lines.pop();
        --this.text.index;      
      }
    }
  }

  findLineWidth(line, font) {
    var s = this.text.lines[line].str;
    if(s == "") return 0;
    var w = 0;
    for(var i = 0; i < s.length; ++i) {
      var c = s.charCodeAt(i) - 32;      
      w += this.table[font].charWidth[c];        
    }
    return w;
  }

  findLinesFonts() {     
    for(var i = 0; i < this.text.lines.length; ++i) {
      var fonts = [];
      for(var j = 0; j < this.table.length; ++j) {
        var w = this.findLineWidth(i, j);        
        if(w <= this.xSize) {
          fonts.push(j);          
        }       
      }
      this.text.lines[i].fonts = fonts;
    }     
  }

  arrangeX(line, font) {   
    var x = this.xSize - this.findLineWidth(line, font);
    this.text.lines[line].font = font;
    this.text.lines[line].x = Math.ceil(x / 2);     
  }

  findSpace(index, lines) {
    var offset = 0;
    var ar = this.text.arrangement;      
    for(var i = 0; i < lines; ++i) {   
      var font = ar[index][i];     
      offset += this.table[font].height;
    }
    return offset;
  }

  findYOffset(index, line) {
    var offset = 0;
    var lines = this.text.arrangement[index].length;  
    var space = this.findSpace(index, lines); 
    var gap = this.ySize + 2 - space;   
    if(gap > 0) {
      switch(lines) {
        case 1:
          offset = Math.ceil((gap - 1) / (lines + 1)) ;
        break;
        default:
          if(gap > lines) offset = line + Math.ceil((gap - lines) / 2);
        break;
      }      
    }  
    offset += this.findSpace(index, line);
    return offset;
  }

  findYArrangement() {
    this.findLinesFonts();
    var lines =  this.text.lines.length;
    if(this.text.lines[lines - 1].str == "") lines -= 1;
    this.text.arrangement = [];
    switch(lines) {
      case 1:
        this.text.arrangement.push([this.text.lines[0].fonts[0]]);
      break;
      case 2:
        var ar = [];
        var finish = false;
        for(var i = 0; i < this.text.lines[0].fonts.length; ++i) {         
          for(var j = 0; j < this.text.lines[1].fonts.length; ++j) {            
            if(finish) break;
            var font1 = this.text.lines[0].fonts[i];
            var font2 = this.text.lines[1].fonts[j];
            var h1 = this.table[font1].height;
            var h2 = this.table[font2].height;
            if(this.ySize + 2 > h1 + h2) {
              if(ar.length > 0) {
                var f1 = ar[ar.length - 1][0];
                var f2 = ar[ar.length - 1][1];
                if(font1 == font2) finish = true; 
                else if(font1 >= f1 && font2 >= f2) break;
              }
              var obj = [];              
              obj.push(font1);
              obj.push(font2); 
              if(finish) ar.unshift(obj);
              else ar.push(obj);              
              break;                         
            }  
          } 
        }        
        this.text.arrangement = ar;
      break;
      case 3:
      var ar = [];
      var finish = false;
      for(var i = 0; i < this.text.lines[0].fonts.length; ++i) {         
        for(var j = 0; j < this.text.lines[1].fonts.length; ++j) {            
          for(var k = 0; k < this.text.lines[2].fonts.length; ++k) {
            if(finish) break;
            var font1 = this.text.lines[0].fonts[i];
            var font2 = this.text.lines[1].fonts[j];
            var font3 = this.text.lines[2].fonts[k];
            var h1 = this.table[font1].height;
            var h2 = this.table[font2].height;
            var h3 = this.table[font3].height;
            if(this.ySize + 2 > h1 + h2 + h3) {
              if(ar.length > 0) {
                var f1 = ar[ar.length - 1][0];
                var f2 = ar[ar.length - 1][1];
                var f3 = ar[ar.length - 1][2];
                if(font1 == font2 && font2 == font3) finish = true; 
                else if(font1 >= f1 && font2 >= f2 && font3 >= f3) break;
              }
              var obj = [];              
              obj.push(font1);
              obj.push(font2);              
              obj.push(font3); 
              if(finish) ar.unshift(obj);
              else ar.push(obj);              
              break;                         
            }  
          } 
        }
      }     
      this.text.arrangement = ar;
      break;
      case 4:
      var ar = [];
      var finish = false;
      for(var i = 0; i < this.text.lines[0].fonts.length; ++i) {         
        for(var j = 0; j < this.text.lines[1].fonts.length; ++j) {            
          for(var k = 0; k < this.text.lines[2].fonts.length; ++k) {
            for(var l = 0; l < this.text.lines[3].fonts.length; ++l) {
              if(finish) break;
              var font1 = this.text.lines[0].fonts[i];
              var font2 = this.text.lines[1].fonts[j];
              var font3 = this.text.lines[2].fonts[k];
              var font4 = this.text.lines[3].fonts[l];
              var h1 = this.table[font1].height;
              var h2 = this.table[font2].height;
              var h3 = this.table[font3].height;
              var h4 = this.table[font4].height;
              if(this.ySize + 2 > h1 + h2 + h3 + h4) {
                if(ar.length > 0) {
                  var f1 = ar[ar.length - 1][0];
                  var f2 = ar[ar.length - 1][1];
                  var f3 = ar[ar.length - 1][2];
                  var f4 = ar[ar.length - 1][3];
                  if(font1 == font2 && font3 == font4 && font1 == font3) finish = true;
                  else if(font1 >= f1 && font2 >= f2 && font3 >= f3 && font4 >= f4) break;
                }
                var obj = [];              
                obj.push(font1);
                obj.push(font2);              
                obj.push(font3); 
                obj.push(font4);
                if(finish) ar.unshift(obj);
                else ar.push(obj);                 
                break;                         
              }  
            }
          } 
        }
      }      
      this.text.arrangement = ar;
      break;
    }        
  }

  arrangeXY(index) {
    this.findYArrangement();
    var ar = this.text.arrangement;    
    var lines = ar[index].length;    
    for(var i = 0; i < lines; ++i) {
      var font = ar[index][i];      
      this.text.lines[i].y = this.findYOffset(index, i);
      this.arrangeX(i, font);       
    }  
    return lines;        
  }
  
  printAllLines() {
    this.resetPage();       
    var lines = this.arrangeXY(this.text.arrIndex);
    for(var i = 0; i < lines; ++i) {
      this.putW(i);
    }    
    this.updatePage();   
    this.imageMapUpdatedFlag = true;
    this.painting.selection = false;
  }
  
  putW(l) {      
    var text = this.text.lines[l].str;
    var color = this.text.lines[l].color;
    //if(text == "") return 0;
    var xPos = this.text.lines[l].x;
    var yPos = this.text.lines[l].y;
    var font = this.text.lines[l].font;
    for(var i = 0; i < text.length; ++i) {
      var w = this.putC(text.charAt(i), color.charAt(i), xPos, yPos, font);
      if(w == 0) { xPos = -1; break; }
      xPos += w;
    }        
    return xPos;
  }

  putC(key, color, xPos, yPos, tIndex) {
    var c = key.charCodeAt(0) - 32;
    var col = color.charCodeAt(0) - 32; 
    var font = this.table[tIndex].font;
    var cMaxWidth = this.table[tIndex].width;
    var cHeight = this.table[tIndex].height;
    var cWidth = this.table[tIndex].charWidth[c];
    //if(xPos + cWidth > this.xSize + 1) return 0;
    //if(yPos + cHeight > this.ySize + 1) return 0;
    for(var i = 0; i < cHeight; ++i) {           
      for(var j =  0; j < cWidth - 1; ++j) {
        var index = this.xSize * (i + yPos) + j + xPos; 
        this.imageMap[index] = 0;
        var ch = font[i].charAt(j + c * cMaxWidth);        
        if(ch == '*') {          
          this.imageMap[index] = this.fiveColor ? col : 1;
        } 
      }  
    }
    return cWidth;
  }

  arrange() {
    var index = this.text.arrIndex + 1;
    var len = this.text.arrangement.length;
    if(index < len) this.text.arrIndex = index;
    else this.text.arrIndex = 0;
    this.printAllLines();
  }

  createFontTable(font) {
    var bufLen = font[0].length; 
    var xChar = bufLen / 96;
    var yChar = font.length;    
    var w = [];
    for(var i = 0; i < 96; ++i) {            
      var wChar = 1;
      for(var j = 0; j < xChar; ++j) {        
        for(var k = 0; k < yChar; ++k) {
          var pixel = font[k].charAt(i * xChar + j);
          if(pixel == '*') {
            wChar +=  1;
            break;
          }
        }
      }
      w.push(wChar);     
    }
    w[0] = Math.round(xChar / 2);
    this.table.push({width: xChar, height: yChar, charWidth: w, font: font});       
  }

  createFontMap(i) {
    var font = [];
    var bmp = atob((this.fonts[i].fle as string).split(',')[1]);  
    var check = this.analyzeBMP(bmp);
    if(!check.err) {                
      var offset = check.offset;
      var width = check.width;
      var height = check.height;      
      var adj = width % 4;
      var font_ = [];
      for(var l = 0; l < 3; ++l) {
        for(var j = 0; j < height / 3; j++){     
          var str = '';     
          for(var k = 0;  k < width; k++) {
            var b = bmp.charCodeAt(offset++);     
            if(b == 255) str += '*';
            else str += ' ';                                                       
          }
          font_.push(str);         
          offset += adj;
        } 
      }     
        
      var len = font_.length / 3;  
      for(var j = len - 1; j >= 0; --j) {
        font.push(font_[j + 2 * len] + font_[j + len] + font_[j]);        
      }   
    }    
    return font;
  }

  checkTable() {
    if(this.table.length == 0) {
      for(var i = 0; i < this.fonts.length; ++i) {
        this.createFontTable(this.createFontMap(this.fonts.length - 1 - i));          
      }        
    }  
  }

  setFGColor(i) {
    this.fgColor = i;    
  }

  back() {
    this.router.navigate(['/display']);
  }

  ngAfterViewInit() {
    if(!this.fonts) {
      this.http.post('/image/dump', {type_in: 16}).subscribe(font => {
        this.fonts = font;
        this.cache.setImageSet(16, font);           
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();    
    this.fontChangeSubscription_.unsubscribe(); 
  }
}
