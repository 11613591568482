import { Component, Input, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Cache } from '../shared/cache.service';
import { CompanyComponent } from './company.component';

@Component({
  selector: 'app-remove-company',
  templateUrl: '../shared/remove.permission.html',
  styleUrls: ['./company.component.css', '../shared/global.css']
})

export class RemoveCompanyComponent {
  @Input() companies: any;
  @Input() user: any;
  @Input() index: any;
  private companyComponent: CompanyComponent;

  constructor(
    @Inject(FormBuilder) formBuilder:FormBuilder, 
    private http: HttpClient, 
    private cache: Cache,
    companyComponent: CompanyComponent) {
      this.companyComponent = companyComponent;
  }  

  remove() {        
    if(true /*this.showDeleteFlag*/) {      
      var company_ = this.companies[this.index].cnm;
      this.http.post('/company/remove', {company_in: company_}).subscribe(
        company => { 
          this.cache.setCompanyList(company); 
          this.cancel(); 
          (<any>window).ga('send', 'event', {
            eventCategory: 'Company Remove',
            eventLabel: this.user.email,
            eventAction: company_,
            eventValue: 2
          });
        });
    } else {
      //this.showDeleteFlag = true;
      //this.editIndex = i;
    };
  }

  cancel() {
     this.companyComponent.cancel();
  }
}
