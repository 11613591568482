import { Component, Input, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Cache } from '../shared/cache.service';
import { BranchComponent } from './branch.component';

@Component({
  selector: 'app-add-branch',
  templateUrl: './add.branch.component.html',
  styleUrls: ['./branch.component.css', '../shared/global.css']
})

export class AddBranchComponent {
  @Input() companies: any;
  @Input() user: any;
  private branchComponent: BranchComponent;
  branch: FormControl = new FormControl(null, Validators.required);
  company: FormControl = new FormControl(null, Validators.required);
  usersNo: FormControl = new FormControl(null, Validators.required);
  addBranchGroup: FormGroup;
  
  constructor(
    @Inject(FormBuilder) formBuilder:FormBuilder, 
    private http: HttpClient, 
    private cache: Cache,
    branchComponent: BranchComponent) {
      this.branchComponent = branchComponent;
      this.addBranchGroup = formBuilder.group({      
        branch_in: this.branch,
        company_in: this.company,
        users_no: 4              
    });
  }  

  add() {  
    if(this.addBranchGroup.valid) {
      this.http.post('/branch/add', this.addBranchGroup.value).subscribe(
        branch => { 
          this.cache.setBranchList(branch); 
          this.cancel();           
          (<any>window).ga('send', 'event', {
            eventCategory: 'Branch Add',
            eventLabel: this.user.email,
            eventAction: this.branch.value,
            eventValue: 1
          });
        });  
    }  
  }

  selectCompany(i) {
    this.addBranchGroup.patchValue({company_in: this.companies[i].cnm});
  }

  cancel() {
     this.branchComponent.cancel();
  }
}
