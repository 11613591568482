import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CompanyComponent } from './company.component';
import { AddCompanyComponent } from './add.company.component';
import { EditCompanyComponent } from './edit.company.component';
import { RemoveCompanyComponent } from './remove.company.component';
import { SharedModule } from '../shared/shared.module';
//import { ToolbarComponent } from '../toolbar/toolbar.component';

const companyRoutes: Routes = [
    {
    path: 'company',
    component: CompanyComponent
    }
];

@NgModule({
  declarations: [
    CompanyComponent, 
    AddCompanyComponent,
    EditCompanyComponent,
    RemoveCompanyComponent
    //ToolbarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forChild(companyRoutes)
  ],
  bootstrap: [
    CompanyComponent
  ],
  exports: [
    CompanyComponent  
  ],
})

export class CompanyModule {}