import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service'

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css', '../shared/global.css']
})
export class UserComponent implements OnInit, OnDestroy {  
  repList: any = ['all', 'geo', 'msg', 'battery', 'geo_battery', 'none'];
  enList: any = [{desc: 'Yes'}, {desc: 'No'} ];
  email: FormControl = new FormControl(null, Validators.required);
  access: FormControl = new FormControl(null, Validators.required);
  branch: FormControl = new FormControl(null, Validators.required);
  password: FormControl = new FormControl(null, Validators.required);
  confirmPassword: FormControl = new FormControl(null, Validators.required);
  fname: FormControl = new FormControl(null, Validators.required);
  lname: FormControl = new FormControl(null, Validators.required);  
  enable: FormControl = new FormControl(null, Validators.required);
  report: FormControl = new FormControl(null, Validators.required);
  addUserGroup: FormGroup;
  editUserGroup: FormGroup;
  private userChangeSubscription_: Subscription;
  private branchChangeSubscription_: Subscription;
  private taskChangeSubscription_: Subscription;
  userCache: any;
  branchCache: any;
  users : any;
  showAddFlag: Boolean = false;
  showEditFlag: Boolean = false;
  showDeleteFlag: Boolean = false;
  showEnable: Boolean = true;
  editIndex: any = -1;
  showSignUserFlag: Boolean = false;
  signUserIndex: any = -1;
  signUser: any = [];

  constructor(@Inject(FormBuilder) formBuilder:FormBuilder, 
      private http: HttpClient, 
      private cache: Cache) { 

    this.branchChangeSubscription_ = cache.branchChange.subscribe(
      branch => {          
        try { this.branchCache = branch.name; } catch(e) {};
    });

    this.addUserGroup = formBuilder.group({      
        email_in: this.email,        
        access_in: this.access,
        branch_in: this.branchCache,
        password_in: this.password,
        fname_in: this.fname,
        lname_in: this.lname             
    });

    this.editUserGroup = formBuilder.group({  
      email_in: this.email,        
      access_in: this.access,
      branch_in: this.branchCache,
      fname_in: this.fname,
      lname_in: this.lname,
      enable_in: this.enable,
      report_in: this.report            
    });

    this.taskChangeSubscription_ = cache.taskChange.subscribe(
      task => {
        if(task == 'edit') { 
          this.showEditFlag = !this.showEditFlag;
          this.showDeleteFlag = false;
          this.editIndex = -1;   
        }        
    });

    this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.userCache = user; });
    this.cancel();   
  }

  showAddForm() {
    this.showAddFlag = !this.showAddFlag;
    this.editIndex = -1;     
    this.confirmPassword.setValue('');
    this.addUserGroup.patchValue({
      email_in: '', 
      access_in: '',
      branch_in: this.branchCache, 
      password_in: '',
      fname_in: '',
      lname_in: ''
    });
  }

  selectAccess(i) {
    this.addUserGroup.patchValue({access_in: this.userCache.accessList[i]});
  }

  updateAddForm() {
    this.addUserGroup.updateValueAndValidity();
  }

  add() {  
    if(this.addUserGroup.valid) {
      this.http.post('/user/add', this.addUserGroup.value).subscribe(
        user => { 
          this.users = user; 
          this.cancel();           
          (<any>window).ga('send', 'event', {
            eventCategory: 'User Add',
            eventLabel: this.userCache.email,
            eventAction: this.email.value,
            eventValue: 1
          });
        });  
    }  
  }

  cancel() {
    this.showAddFlag = false;   
    this.showEditFlag = false;   
    this.showDeleteFlag = false;
    this.editIndex = -1;  
  }

  showEditForm(i) {    
    if(this.userCache.email == this.users[i].eml) {
      this.showEnable = false;
    } else this.showEnable = true;
    this.editIndex = i;    
    this.showEditFlag = false;
    this.email.setValue(this.users[i].eml);
    this.access.setValue(this.users[i].uac);
    this.branch.setValue(this.users[i].bnm);
    this.fname.setValue(this.users[i].fnm);
    this.lname.setValue(this.users[i].lnm);
    var en = this.users[i].uen;
    if(en == 'Y') this.enable.setValue(this.enList[0].desc);
    else this.enable.setValue(this.enList[1].desc);
    this.report.setValue(this.users[i].urp);
    this.editUserGroup.patchValue({
        email_in: this.email.value, 
        access_in: this.access.value, 
        branch_in: this.branchCache.value, 
        fname_in: this.fname.value,
        lname_in: this.lname.value,
        enable_in: this.enable.value,
        report_in: this.report.value,
      });
  }

  selectReport(i) {
    this.report.setValue(this.repList[i]);
  }

  selectEnable(i) {
    this.enable.setValue(this.enList[i].desc);
  }

  updateEditForm() {
    this.editUserGroup.updateValueAndValidity();
  }

  editForm(i) {
    this.editUserGroup.patchValue({branch_in: this.branchCache});
    this.enable.setValue(this.enable.value.substring(0, 1));
    if(this.editUserGroup.valid) {
      this.http.post('/user/edit', this.editUserGroup.value).subscribe(
        user => { 
          this.users = user; 
          this.cancel(); 
          (<any>window).ga('send', 'event', {
            eventCategory: 'User Edit',
            eventLabel: this.userCache.email,
            eventAction: this.email.value,
            eventValue: 2
          });

        });        
    }
  }

  remove(i) {        
    if(this.showDeleteFlag) {      
      var email_ = this.users[i].eml;
      this.http.post('/user/remove', {email_in: email_, access_in: this.users[i].uac, branch_in: this.users[i].bnm}).subscribe(
        user => { 
          this.users = user; 
          this.cancel(); 
          (<any>window).ga('send', 'event', {
            eventCategory: 'User Remove',
            eventLabel: this.userCache.email,
            eventAction: email_,
            eventValue: 2
          });
        });
    } else {
      this.showDeleteFlag = true;
      this.editIndex = i;
    };
  }

  listSigns(i) {   
    if(this.showSignUserFlag && this.signUserIndex == i) {
      this.signUserIndex = - 1;
      this.showSignUserFlag = false;
    } else {
      this.signUserIndex = i;
      this.showSignUserFlag = true;
      this.http.post('/sign/user/list', {email_in: this.users[this.signUserIndex].eml, branch_in: this.branchCache}).subscribe(signUser => {
        this.signUser = signUser; 
      });
    }    
  }

  changeAccess(j) {
    
    var form = {      
      email_in: this.users[this.signUserIndex].eml, 
      branch_in: this.branchCache,
      sign_in: this.signUser[j].snm,
      serial_no_in: this.signUser[j].ssn      
    }

    if(this.signUser[j].sign == null) {
      this.http.post('/sign/user/add', form).subscribe(signUser => {
        this.signUser = signUser; 
      });
    } else {
      this.http.post('/sign/user/remove', form).subscribe(signUser => {
        this.signUser = signUser; 
      });
    }    
  }

  ngOnInit() {
    if(!this.users) {    
      this.http.post('/user/list', {branch_in: this.branchCache}).subscribe(user => {
        this.users = user; 
      });
    }
  }

  ngOnDestroy() {
    this.branchChangeSubscription_.unsubscribe();  
    this.taskChangeSubscription_.unsubscribe();
    this.userChangeSubscription_.unsubscribe();
  }
}
