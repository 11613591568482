import { Component, Input, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { DeviceService } from '../device.service';
import { Cache } from '../../shared/cache.service';

@Component({
  selector: 'app-rscscommand',
  templateUrl: './rscscommand.component.html',
  styleUrls: ['../../shared/global.css'],
  providers: [DeviceService]
})

export class RSCSCommandComponent implements OnInit, OnDestroy {
  @Input() sign: any;
  @Input() branch: any;
  @Input() serialNo: any;
  @Input() show: Boolean;
  @Input() status: any;

  commandType: FormControl = new FormControl();
  speedLimit: FormControl = new FormControl();
  maxSpeed: FormControl = new FormControl();   
  minSpeed: FormControl = new FormControl(); 
  slowDownNo: FormControl = new FormControl(); 
  frame1Time: FormControl = new FormControl();
  frame2Time: FormControl = new FormControl();   
  frame3Time: FormControl = new FormControl(); 
  blankTime: FormControl = new FormControl(); 
  displayMode: FormControl = new FormControl();
  secondFrameMode: FormControl = new FormControl();   
   
  command: FormControl = new FormControl(null, Validators.required);
  commandGroup: FormGroup;
  private userChangeSubscription_: Subscription;        
  user: any;  
  showDroplist: Boolean = false;

  constructor(@Inject(FormBuilder) formBuilder:FormBuilder, 
      private http: HttpClient, 
      private cache: Cache) {
      this.commandGroup = formBuilder.group({     
          sign_in: this.sign,
          branch_in: this.branch,
          command_in: this.command,
          serial_no_in: this.serialNo               
      });
      this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.user = user; });           
  }

  selectCommand(i) {  
    try {
        var speed = this.status.qur.split(',', 4);
        var time = this.status.mei.split(',',4);
        var flags = this.status.msc.split(',', 1);
        this.speedLimit.setValue(Number(speed[0]));
        this.maxSpeed.setValue(Number(speed[1]));
        this.minSpeed.setValue(Number(speed[2]));
        this.slowDownNo.setValue(Number(speed[3]));
        this.frame1Time.setValue(Number(time[0]));
        this.frame2Time.setValue(Number(time[1]));
        this.frame3Time.setValue(Number(time[2]));
        this.blankTime.setValue(Number(time[3]));
        //if(this.status.son == 'N') this.displayMode.setValue(0);
        //else  this.displayMode.setValue(1);        
        //this.secondFrameMode.setValue(Number(flags[0]));
        this.displayMode.setValue(2);       
        this.secondFrameMode.setValue(2);
        //console.log(speed);
        //console.log(time);
        //console.log(flags);
      } catch(e) {}; 

      this.commandGroup.patchValue({
          sign_in: this.sign,
          branch_in: this.branch,
          command_in: this.command.value,
          serial_no_in: this.serialNo  
      });     
    
      this.commandType.setValue(this.user.rscsCommand[i]);   
      if(i == 0) {
        this.command.setValue('Update DateTime;');       
      } else if(i == 1) {
          this.onSpeedParameters();
      } else if(i == 2) {
         this.onModeParameters();
      }
  }

  findDisplayMode() {
    return this.displayMode.value;
  }

  findSecondFrameMode() {
    return this.secondFrameMode.value;
  }

  onDisplayModeChange(value) {
    //console.log(" Value is : ", value );
    this.displayMode.setValue(value);
    //this.secondFrameMode.setValue(1);
    this.onModeParameters();
  }

  onSecondFrameModeChange(value) {
    //console.log(" Value is : ", value );
    //this.displayMode.setValue(0);
    this.secondFrameMode.setValue(value);
    this.onModeParameters();
  }

  onSpeedParameters() {
    var v1 = this.speedLimit.value;
    var v2 = this.maxSpeed.value;
    var v3 = this.minSpeed.value;
    var v4 = this.slowDownNo.value;
    var v5 = this.frame1Time.value;
    var v6 = this.frame2Time.value;
    var v7 = this.frame3Time.value;
    var v8 =this.blankTime.value;
    var v9 = this.displayMode.value;
    var v10 = this.secondFrameMode.value;
         
      //
      if(v1 && v2 && v3 && v4 && v5 && v6 && v7 && v8) {
          v1 = Number(this.speedLimit.value) % 1000;
          v2 = Number(this.maxSpeed.value) % 1000;
          v3 = Number(this.minSpeed.value) % 1000;
          v4 = Number(this.slowDownNo.value) % 10;
          v5 = Number(this.frame1Time.value) % 10000;
          v6 = Number(this.frame2Time.value) % 10000;
          v7 = Number(this.frame3Time.value) % 10000;
          v8 = Number(this.blankTime.value) % 10000;
          v9 = Number(this.displayMode.value) % 10000;
          v10 = Number(this.secondFrameMode.value) % 10000;
          this.command.setValue(null);
          if(v1 > 9 && v2 > v1 && v3 < v1 && v4 < 4) {
            this.command.setValue('Update ' + v1.toString() + ',' + 
                                            v2.toString() + ',' + 
                                            v3.toString() + ',' + 
                                            v4.toString() + ',' + 
                                            v5.toString() + ',' + 
                                            v6.toString() + ',' + 
                                            v7.toString() + ',' + 
                                            v8.toString() + ',' + 
                                            v9.toString() + ',' +
                                            v10.toString() + ';')
        }  
        console.log(this.command.value)
      }
  } 

  onModeParameters() {
    var v1 = this.displayMode.value;
    var v2 = this.secondFrameMode.value;       
    //
    if((v1 == 0 || v1 == 1) && (v2 == 0 || v2 == 1)) {
      this.command.setValue('Mode ' + v1 + ',' + v2 + ';')
    } else this.command.setValue(null);
    console.log(this.command.value)
} 

  
  onCommand() {
      this.http.post('/sign/command', this.commandGroup.value).subscribe(cmd =>  {
          (<any>window).ga('send', 'event', {
              eventCategory: 'Command',
              eventLabel: this.user.email,
              eventAction: this.command.value,
              eventValue: 4
          });            
          this.command.setValue(null);
          this.show = false;
      });
  }

  ngOnInit() { }

  ngOnDestroy() { 
      this.userChangeSubscription_.unsubscribe();           
  } 

}
