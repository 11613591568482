import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BranchComponent } from './branch.component';
import { AddBranchComponent } from './add.branch.component';
import { SharedModule } from '../shared/shared.module';

const branchRoutes: Routes = [
    {
    path: 'branch',
    component: BranchComponent
    }
];

@NgModule({
  declarations: [
    BranchComponent, 
    AddBranchComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forChild(branchRoutes)
  ],
  bootstrap: [
    BranchComponent
  ],
  exports: [
    BranchComponent  
  ],
})

export class BranchModule {}