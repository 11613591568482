import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css', '../shared/global.css']
})

export class MessageComponent implements OnInit, OnDestroy {
  private userChangeSubscription_: Subscription;
  interval: FormControl = new FormControl(null, Validators.required);
  removeMessageGroup: FormGroup;
  user: any;
  messages: any;
  showRemoveFlag: Boolean = false;

  constructor(
    @Inject(FormBuilder) formBuilder:FormBuilder, 
    private http: HttpClient, 
    private cache: Cache) {
      this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.user = user; });
      this.cancel();
      this.removeMessageGroup = formBuilder.group({      
        interval_in: this.interval              
    });
  }  

  cancel() { 
    this.showRemoveFlag = false; 
  }

  remove() {  
    this.showRemoveFlag = false;
    if(this.removeMessageGroup.valid) {
      this.http.post('/message/remove/all', this.removeMessageGroup.value).subscribe(
        message => { 
          this.messages = message;
          this.cancel();           
          (<any>window).ga('send', 'event', {
            eventCategory: 'Message Recycle',
            eventLabel: this.user.email,
            eventAction: this.interval.value,
            eventValue: 1
          });
        }
      );  
    }  
  }
 
  ngOnInit() {
    if(!this.messages) {
      this.http.get('/message/list').subscribe(message => {
        this.messages = message;       
      });
    }
  }

  ngOnDestroy() {
    this.userChangeSubscription_.unsubscribe();
  }
}
