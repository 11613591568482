import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { UserModule } from './user/user.module';
import { DisplayModule } from './display/display.module';
import { SharedModule } from './shared/shared.module';
import { CompanyModule } from './company/company.module';
import { BranchModule } from './branch/branch.module';
import { SignModule } from './sign/sign.module';
import { ImageModule } from './image/image.module';
import { SetupModule } from './setup/setup.module';
import { BoardModule } from './board/board.module';
import { HexModule } from './hex/hex.module';
import { MessageModule } from './message/message.module';
import { ArchiveModule } from './archive/archive.module';
 
const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' }
  },
  {
    path: 'branch',
    loadChildren: './branch/branch.module',
    data: { title: 'Branch' }
  },
  {
    path: 'company',
    loadChildren: './company/company.module',
    data: { title: 'Company' }
  },  
  {
    path: 'user',
    loadChildren: './user/user.module',
    data: { title: 'User' }
  },
  {
    path: 'sign',
    loadChildren: './sign/sign.module',
    data: { title: 'Sign' }
  },  
  {
    path: 'display',
    loadChildren: './display/display.module',
    data: { title: 'Display' }
  },  
  {
    path: 'image',
    loadChildren: './image/image.module',
    data: { title: 'Image' }
  }, 
  {
    path: 'board',
    loadChildren: './board/board.module',
    data: { title: 'Board' }
  }, 
  {
    path: 'hex',
    loadChildren: './hex/hex.module',
    data: { title: 'Hex' }
  }, 
  {
    path: 'message',
    loadChildren: './message/message.module',
    data: { title: 'Message' }
  },   
  {
    path: 'archive',
    loadChildren: './archive/archive.module',
    data: { title: 'Archive' }
  },
  { path: '',   redirectTo: '/login', pathMatch: 'prefix' }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    CompanyModule,
    BranchModule,  
    UserModule,  
    SignModule,
    DisplayModule,
    ImageModule,
    SetupModule, 
    BoardModule,   
    HexModule,
    MessageModule,
    ArchiveModule,
    RouterModule.forRoot(
      appRoutes,
      { useHash: false, enableTracing: false } // <-- debugging purposes only
    )
  ],
  providers: [
    CookieService
    //Cache,    
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
