import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})

export class SetupComponent implements OnInit, OnDestroy {
  file: FormControl = new FormControl();
  device: FormControl = new FormControl();
  private userChangeSubscription_: Subscription;
  user: any;
  messageGroup: FormGroup;
  files: any;

  constructor(@Inject(FormBuilder) formBuilder:FormBuilder, 
    private http: HttpClient, 
    private router: Router,
    private cache: Cache) {   

      this.messageGroup = formBuilder.group({      
          file: '',
          device: ''      
      });
      this.files = [];
      this.files.push('Hello');

      this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.user = user; });
  }

  onFileChange(event) {
    let files = [].slice.call(event.target.files);
    //this.device = files.map(f => f.name).join(', ');  
    files.map(f => this.files.push(f.name));    
    if(event.target.files && event.target.files.length > 0) {
      var promise = [];
      var data = [];
      for(var i = 0; i < event.target.files.length; ++i) {        
        promise.push(new Promise((resolve, reject) => {
          let fileName = event.target.files[i].name;
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
          reader.onload = () => {
            var jsonStr = atob((reader.result as string).split(',')[1]);
            var file = JSON.stringify(jsonStr);
            //var json = JSON.parse(file);
            data.push({
              branch_in: 'Global Traffic Equipment',
              sign_in: (fileName as string).split('.')[0],
              ts_in: new Date().getTime().toString(),
              index_in: '0',
              file_in: file
            });
            resolve();
          }
        }));      
      }
      Promise.all(promise).then((values) => {
          var promise = [];
          for(var i = 0; i < data.length; ++i) {
            promise.push(new Promise((resolve, reject) => {
              var cmd = 'update 1@' + data[i].ts_in;
              var sign = data[i].sign_in;
              this.http.post('/message/add', data[i]).subscribe(
                res => {     
                  this.http.post('/sign/command', {                    
                    branch_in: 'Global Traffic Equipment', 
                    sign_in: sign, 
                    command_in: cmd}).subscribe(res => {
                      resolve();
                    }
                  );                           
                }
              );              
            }));
          }

          Promise.all(promise).then((values) => {
            //for(var i = 0; i < data.length; ++i) {  }
            (<any>window).ga('send', 'event', {
              eventCategory: 'Message Add',
              eventLabel: this.user.email,
              eventAction: data[0].sign_in + '@' + data[0].ts_in,
              eventValue: 1
            });
          });
        }
      );
    }
  }

  send() {    
    this.http.post('/message/add', this.messageGroup.value).subscribe(r => {}, error => {}) 
  }

  ngOnInit() {
  }
  
  ngOnDestroy() {
    this.userChangeSubscription_.unsubscribe();
  }
}
