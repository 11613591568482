import { Component, ElementRef, ViewChild, HostListener, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service';
import { ConceptComponent } from './concept.component';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['../shared/global.css']
})

export class SchedulingComponent extends ConceptComponent implements OnInit, OnDestroy {
  @ViewChild('textModeInput', {static: true}) textModeInput: ElementRef;
  @ViewChild('imageInput', {static: true}) imageInput: ElementRef;
  
  private userChangeSubscription_: Subscription;     
  screenMode: FormControl = new FormControl(null, Validators.required);
  order: FormControl = new FormControl(null, Validators.required);
  display: FormControl = new FormControl(null, Validators.required);
  blank: FormControl = new FormControl(null, Validators.required);
  //textMode: FormControl = new FormControl();
  user: any;
  sign: any;
  branch: any;
  serialNo: any;
  notSentFlag: Boolean = true;
  showSchedulingFlag: Boolean = false;
  json: any = {ts: "", cmd: "update 1@", index: "1", sch: 0, sl: 40, sd: "", st: "", fd: "", ft: "", fms: ""};
  images: any;
  imageFlag: Boolean = false;  
  textInputModel: any = '';
  persistentCanvas: any;
  transientCanvas: any;
  persistentNo: number = 100;
  persistentIndex: number;
  lastPersistentIndex: number;
  pageChangedFlag: Boolean = true;  
  
  constructor(activatedRoute: ActivatedRoute,
              public router: Router,
              public cache: Cache,
              public http: HttpClient) { 
    super(activatedRoute, router, cache, http); 
    this.userChangeSubscription_ = cache.userChange.subscribe(user => { this.user = user; });   
    this.painting.enable = true;    
    this.resetAllCanvasLayers();    
  }

  @HostListener('window:scroll', ['$event']) 
  doSomething(event) {
    this.scrollXOffset = window.pageXOffset;
    this.scrollYOffset = window.pageYOffset;
    //console.log("Scroll Event", this.scrollXOffset);    
  }

  getNewLayer() {
    var layer = [];
    for(var j = 0; j < this.ySize; ++j) {
      for(var i = 0; i < this.xSize; ++i) {
        layer.push(0);
      }        
    } 
    return layer;
  }

  resetTransientCanvas() {
    this.transientCanvas = this.getNewLayer();    
  }

  resetAllCanvasLayers() {   
    this.resetTransientCanvas(); 
    this.persistentCanvas = [];      
    for(var i = 0; i < this.persistentNo + 1; ++i) {
      this.persistentCanvas.push(this.getNewLayer());
    }
    this.persistentIndex = 0;  
    this.lastPersistentIndex = this.persistentIndex;     
  }

  move(event: MouseEvent) {    
    this.checkPageChanged();
    this.checkTextImageBuffered();
    //if(this.imageMapUpdatedFlag) this.transientLayerEnable = false;
    if(this.pasteLayerFlag) {
      this.transientLayerEnable = true;
      this.painting.selection = true;
    }

    if(this.transientLayerEnable) {
      var ixy = this.mouseXY2DisplayXY(event);    
      this.painting.x2 = ixy.x;
      this.painting.y2 = ixy.y;
      this.resetTransientCanvas();
      if(this.pasteLayerFlag) {
        this.moveCopyCache(ixy, this.transientCanvas, false);
      } else if(this.drawLineFlag) {
        this.drawLine(this.transientCanvas);        
      } else if(this.drawCircleFlag) {
        this.drawCircle(this.transientCanvas);
      } else if(this.fillCircleFlag) {
        this.fillCircle(this.transientCanvas);
      } else if(this.drawSquareFlag) {
        this.drawSquare(this.transientCanvas);
      } else if(this.fillSquareFlag) {
        this.fillSquare(this.transientCanvas);
      }   
      this.refreshCanvas();    
      //console.log('xm:' + (event.clientX - this.canvasOffset) + ' ym: ' + event.clientY + ' sx: ' + event.screenX + ' sy: ' + event.screenY);  
    }    
  }

  reflectFromImageMap() {        
    for(var i = 0; i < this.persistentCanvas[0].length; ++i) {
      this.persistentCanvas[this.persistentIndex][i] = this.imageMap[i];      
    }        
  }

  mergePersistent() {
    for(var i = 0; i < this.persistentCanvas[0].length; ++i) {      
      var ix = this.persistentCanvas[this.persistentIndex][i];     
      if(ix > 0) this.imageMap[i] = ix;
      else this.imageMap[i] = 0;
    }     
  }

  mergeTransient() {
    for(var i = 0; i < this.persistentCanvas[0].length; ++i) {      
      var t = this.transientCanvas[i];     
      if(t > 0) this.persistentCanvas[this.persistentIndex][i]  = t;
    } 
    this.mergePersistent();
    this.resetTransientCanvas(); 
  }

  refreshCanvas() { 
    for(var j = 0; j < this.ySize; ++j) {
      for(var i = 0; i < this.xSize; ++i) {
        var ind = i + this.xSize * j;
        var t = this.transientCanvas[ind];
        var p = this.persistentCanvas[this.persistentIndex][ind];     
        if(this.pasteLayerFlag) {
          if(i >= this.painting.x1 && j >= this.painting.y1 && i <= this.painting.x2 && j <= this.painting.y2) {
            p = 0;
          }
        }   
        if(t > 0) this.imageMap[ind] = t;
        else if(p > 0) this.imageMap[ind] = p;
        else this.imageMap[ind] = 0;
      }        
    }   
    this.updatePage();   
  }

  copy2Persistent() {
    this.mergePersistent();
    this.resetTransientCanvas();
    if(++this.persistentIndex > this.persistentNo) this.persistentIndex = 0;
    this.lastPersistentIndex = this.persistentIndex;
    this.reflectFromImageMap();  
  }

  checkPageChanged() {
    var ret: Boolean = false;
    if(this.pageChangedFlag) {
      //this.resetAllCanvasLayers(); 
      this.resetTransientCanvas();
      this.reflectFromImageMap();          
      this.pageChangedFlag = false;
      this.imageMapUpdatedFlag = false;
      if(this.pasteLayerFlag) {        
        this.transientLayerEnable = true;
        this.painting.selection = true;
      } else {
        this.transientLayerEnable = false;
        this.painting.selection = false;
      }
      ret = true;
    }
    return ret;
  }

  checkTextImageBuffered() {
    if(this.imageMapUpdatedFlag) {      
      this.imageMapUpdatedFlag = false;
      this.resetAllCanvasLayers(); 
      this.reflectFromImageMap();   
      this.copy2Persistent();           
    } 
  }

  copySelectedArea2Cache() {
    this.copyPasteObj.valid = true;
    this.copyPasteObj.canvas = this.getNewLayer();
    var ixy: any = this.findSelectArea();
    this.copyPasteObj.xLen = ixy.x2 + 1;
    this.copyPasteObj.yLen = ixy.y2 + 1;
    for(var i = 0; i < this.copyPasteObj.xLen; ++i) {
      for(var j = 0; j < this.copyPasteObj.yLen; ++j) {
        var ind = i + (j * this.xSize); 
        var ind1 = ixy.x1 + i + ((j + ixy.y1) * this.xSize);
        this.copyPasteObj.canvas[ind] = this.persistentCanvas[this.persistentIndex][ind1];            
      }
    }  
  }

  checkSelection(ixy) {
    var ret: Boolean = false;
    if(this.painting.selection) {
      if(this.pasteLayerFlag) {        
        this.moveCopyCache(ixy, this.persistentCanvas[this.persistentIndex], true);
        this.updateLayer();
        this.pasteLayerFlag = false;
        this.painting.selection = false;
      } else {
        if(this.painting.firstPositionDone) {        
          this.painting.firstPositionDone = false;
          this.transientLayerEnable = false;
          this.copySelectedArea2Cache();      
          //this.selectLayerPressed();
        } else {
          //this.selectLayerPressed();         
          this.painting.firstPositionDone = true;
          this.transientLayerEnable = true;
          this.painting.x1 = ixy.x;
          this.painting.y1 = ixy.y;
          this.painting.x2 = ixy.x;
          this.painting.y2 = ixy.y;
        }
      }      
      ret = true;
    }      
    return ret;
  }

  cutLayerPressed() {
    //this.copyLayerPressed();
    if(this.painting.selection) {
      var ixy = this.findSelectArea();
      for(var i = 0; i < ixy.x2 + 1; ++i) {
        for(var j = 0; j < ixy.y2 + 1; ++j) {
          var ind = ixy.x1 + i + (j + ixy.y1) * this.xSize;
          this.persistentCanvas[this.persistentIndex][ind] = 0;
        }
      }
      this.updateLayer();
      this.refreshCanvas();
    } 
    this.resetAllDrawFlags();
  }

  copyLayerPressed() {
    this.resetAllDrawFlags();
  }

  pasteLayerPressed() {
    if(this.copyPasteObj.valid) {
      if(this.pasteLayerFlag) {
        this.resetAllDrawFlags();
      } else {
        this.resetAllDrawFlags();
        this.pasteLayerFlag = true;      
      }      
    } 
  }

  updateLayer() {
    this.textInputModel = '';
    this.clearText();
    this.copy2Persistent();
  }

  click(event: MouseEvent) {
    var ixy = this.mouseXY2DisplayXY(event);       
    this.checkPageChanged();
    this.checkTextImageBuffered();
    if(this.persistentIndex < 1) this.updateLayer();
    if(!this.checkSelection(ixy)) {
      if(this.pixelDrawFlag) {
        this.painting.x2 = ixy.x;
        this.painting.y2 = ixy.y;        
        this.putPixel(this.persistentCanvas[this.persistentIndex]);
        this.updateLayer(); 
      } else if(this.drawLineFlag || this.fillSquareFlag || 
                  this.drawCircleFlag || this.drawSquareFlag || this.fillCircleFlag) {
        if(this.transientLayerEnable) {
          this.transientLayerEnable = false;
          if(this.drawLineFlag) {
            this.drawLine(this.persistentCanvas[this.persistentIndex]);
          } else if(this.drawSquareFlag) {
            this.drawSquare(this.persistentCanvas[this.persistentIndex]);
          } else if(this.fillSquareFlag) {
            this.fillSquare(this.persistentCanvas[this.persistentIndex]);
          } else if(this.drawCircleFlag) {
            this.drawCircle(this.persistentCanvas[this.persistentIndex]);
          } else if(this.fillCircleFlag) {
            this.fillCircle(this.persistentCanvas[this.persistentIndex]);
          }
          this.updateLayer(); 
        } else {
          this.transientLayerEnable = true;
          this.painting.x1 = ixy.x;
          this.painting.y1 = ixy.y;                
        }
      }              
    }    
    this.refreshCanvas();
  }

  retrieveLayer() {
    this.mergePersistent();
    this.refreshCanvas();
    this.clearText();
    this.textInputModel = '';  
    this.painting.selection = false;
  }

  undo() {
    this.checkTextImageBuffered();
    if(this.persistentIndex > 0) --this.persistentIndex;
    this.retrieveLayer();
  }

  redo() {    
    this.checkTextImageBuffered();
    if(++this.persistentIndex > this.lastPersistentIndex) --this.persistentIndex;    
    this.retrieveLayer();
  }

  updateMode() {
    var mode = this.frames[this.page - 1].mod;    
    if(mode == 3) {
      this.screenMode.setValue(this.modes[0].desc);      
    } else if(mode == 2) this.screenMode.setValue(this.modes[1].desc);
    else this.screenMode.setValue(this.modes[2].desc);
    this.validateFrames();
  }

  update() {        
    this.order.setValue(this.page - 1);
    //this.timestamp.setValue(new Date().toUTCString().replace(/ /g, '*'));
    this.display.setValue(this.frames[this.page - 1].disp);
    this.blank.setValue(this.frames[this.page - 1].blank);
    this.updateMode();   
    this.pageChangedFlag = true;
  }

  rewind() {
    this.page = 1;
    this.textInputModel = '';
    this.update();
  }

  forward() {
    this.frmUpdate();
    this.page += 1;
    if(this.page == this.frames.length + 1) this.page = 1;
    this.textInputModel = '';
    this.clearText(); 
    this.update();    
  }

  backward() {   
    this.frmUpdate();
    if(this.page == 1 || this.page == 0) this.page = this.frames.length;
    else this.page -= 1;
    this.textInputModel = '';
    this.clearText(); 
    this.update();
  }

  play() {
    this.update();
    this.page = 0;    
  }

  setTimestampCommand(ts_, cmd_, idx_) {
    this.json.ts = ts_;
    this.json.cmd = 'update ' + cmd_ + '@' + ts_;
    this.json.index = idx_;    
  }

  normalizeFrames() {    
    var frames = this.frames;    
    for(var i = 0; i < frames.length; ++i) {
      if(frames[i].mod == 3) {
        frames[i].scr = this.auxData[i].radar;
      } else frames[i].scr.replace(/\r\n/g, '\\r\\n');
      if(i == 0 && frames[0].scr == '') return '';
    } 
    return frames;
  }

  normalizeScheduling2Save() {
    var json = this.json;
    var frames = this.frames;    
    for(var i = 0; i < frames.length; ++i) {
      frames[i].scr.replace(/\r\n/g, '\\r\\n');
      if(i == 0 && frames[0].scr == '') return '';
    } 
    json.fms = frames;    
    return JSON.stringify(json);
  }
  
  send(no, last) {        
    this.json.fms = this.normalizeFrames();  
    if(this.json.fms == '') return; 
    var obj = {
      branch_in: this.branch,
      sign_in: this.sign,
      index_in: this.json.index,
      serial_no_in: this.serialNo,
      ts_in: this.json.ts,
      file_in: JSON.stringify(this.json)
    }    
    var cmd = this.json.cmd; //'update ' + no + '@' + obj.ts_in;
    //console.log(obj);
    this.http.post('/message/add', obj).subscribe(
      res => {                     
        if(!last) return;    
        this.http.post('/sign/command', {                    
          branch_in: obj.branch_in, 
          sign_in: obj.sign_in, 
          serial_no_in: obj.serial_no_in,
          command_in: cmd}).subscribe(res => {
            var cat = 'Update Message';
            if(no > 1) cat = 'Update Scheduling => ' + no;
            (<any>window).ga('send', 'event', {
              eventCategory: cat ,
              eventLabel: this.user.email,
              eventAction: this.sign,
              eventValue: 6
          });
          }
        );                           
      }
    ); 
  }

  back() {
    this.router.navigate(['/display']);
  }

  image() {
    this.imageFlag = !this.imageFlag; 
  }

  selectMode(i) {
    this.frames[this.page - 1].mod = this.modes[i].mode;
    this.screenMode.setValue(this.modes[i].desc);
    this.updateRadarString();
  }

  displayTimeChange() {
    if(this.page - 1 == 0) {
      for(var i = 0; i < this.frames.length; ++i) this.frames[i].disp = this.display.value;
    } else this.frames[this.page - 1].disp = this.display.value;
  }

  blankTimeChange() {
    if(this.page - 1 == 0) {
      for(var i = 0; i < this.frames.length; ++i) this.frames[i].blank = this.blank.value;
    } else this.frames[this.page - 1].blank = this.blank.value;
  }

  textModeChange(event) {
    this.keyPressed(event, this.textInputModel);
    if(event.keyCode == 13) this.textInputModel = '';
  }

  setFGColor(i) {
    super.setFGColor(i);
    this.textModeInput.nativeElement.focus();
  }

  sendSch: Boolean = false; 
  sendMsg: Boolean = false;  

  sendMessage() {
    this.sendMsg = true;  
    this.sendSch = false;     
  }

  sendScheduling() {    
    this.sendSch = true;  
    this.sendMsg = false;
  }

  showScheduling() {
    this.cache.task('showScheduling');
  }

  saveScheduling() {
    this.cache.task('saveScheduling');
  }

  deleteFrame() {
    this.cache.task('deleteFrame');
    //this.resetAllCanvasLayers();
  }

  onFileChange(event) {
    if(event.target.files && event.target.files.length > 0) {      
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        var err = false;
        var bmp = atob((reader.result as string).split(',')[1]);
        var check = this.analyzeBMP(bmp);
        if(!check.err) {                
          if(this.xSize == check.width && this.ySize == check.height) {
            this.renderImage(bmp);
          } else err = true;                
        } else err = true;  

        if(err) {
          this.error = true; 
          this.errorMessage = 'Error: Invalid Format! Needs a 16 Color BMP of ' + this.xSize + ' X ' + this.ySize;
        }
      }          
    }      
    this.imageInput.nativeElement.value = "";
  }

  sendConfirmed() {
    //if(this.frames[0].scr == "") return;
    if(this.sendSch) this.cache.task('sendScheduling'); 
    else this.cache.task('send'); 
    this.cancel();   
    
  }

  cancel() {
    this.sendMsg = false;
    this.sendSch = false;
  }  

  ngOnInit() {
    this.update();
    this.scan();
    if(!this.images) {
      var type = this.type;
      if(type == 4) type = 3; // the same image set we use
      this.http.post('/image/dump', {type_in: type.toString()}).subscribe(images => {
        this.cache.setImageSet(type, images);    
      });
    }   
  }

  ngOnDestroy() {
    super.ngOnDestroy(); 
    this.userChangeSubscription_.unsubscribe();    
  }
}
