import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduling-graph',
  templateUrl: './scheduling.graph.component.html',
  styleUrls: ['../shared/global.css']
})

export class SchedulingGraphComponent implements OnInit {
  @ViewChild('myScheduling', {static: true}) schedulingRef: ElementRef;  
  @Input() scheduling: any;
  today: any;
  color: any = ['#000000', '#FF0000','#008000','#FFFF00', '#0000FF', '#00FFFF', '#9400D3', '#FFFFFF'];

  width = 4320;
  height = 400;
  dayWidth = 192;

  setTodayDate() {
    var d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    this.today = d;
  }

  timeDiffInMins(time, type) {
    var ret = 0;
    if(time == '') {
      if(type == 'f') ret = this.dayWidth;
      
    } else {
      var arr = (time as string).split(':');    
      ret = Math.floor(parseInt(arr[1]) + parseInt(arr[0]) * 60);      
      ret = Math.floor((this.dayWidth * ret) / (24 * 60)); // graphic width calibration 
    }
    return ret;
  }

  dateDiffInDays(date, type) {
    if(date == '') {
      if(type == 'f') return 100;
      return 0;
    }
    var dt1 = this.today;
    var dt2 = new Date(date);
    var ret = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));   
    return ret;
  }

  renderScheduling(n, ctx) {    
    var sd = this.dateDiffInDays(this.scheduling[n].json.sd, 's');
    var fd = this.dateDiffInDays(this.scheduling[n].json.fd, 'f');
    var st = this.timeDiffInMins(this.scheduling[n].json.st, 's');
    var ft = this.timeDiffInMins(this.scheduling[n].json.ft, 'f');

    ctx.fillStyle = this.color[n]; 
    if(fd >= sd) {
      if(fd == sd && ft < st) return;
      if(this.scheduling[n].json.sch == 0) {        
        ctx.fillRect(sd * this.dayWidth + st, 10 + 10 * n, (fd - sd) * this.dayWidth + ft - st, 210 - 10 * n);
      } else {
        for(var i = sd; i <= fd; ++i) {
          ctx.fillRect(i * this.dayWidth + st, 10 + 10 * n, ft - st, 210 - 10 * n);
        }        
      }
    }       
  }

  render(): void {  
    let ctx: CanvasRenderingContext2D =  this.schedulingRef.nativeElement.getContext('2d');
    ctx.canvas.width = this.width;
    ctx.canvas.height = this.height;  
    ctx.fillStyle = this.color[0]; 
    ctx.fillRect(0, 0, this.width, this.height - 140);

    this.setTodayDate();    
    for(var i = this.scheduling.length - 1; i > 0; --i) {
      this.renderScheduling(i, ctx);
    }    

    ctx.font = "18px Comic";
    for(var i = 0; i < this.width / this.dayWidth; ++i) {
        if(i % 2 == 0) ctx.fillStyle = '#F5CA09ED';
        else ctx.fillStyle = '#F5F5DC'; 
        ctx.fillRect(i * this.dayWidth, this.height - 180, this.dayWidth, 40);
        ctx.fillStyle = this.color[0];
        var d = new Date();
        d.setDate(this.today.getDate() + i);               
        ctx.fillText(d.toDateString(), i * this.dayWidth + 20, this.height - 156);
    }

    ctx.font = "16px Arial";
    for(var i = 1; i < 5; ++i) {
      var pitch = 6 * this.dayWidth;
      for(var j = 0; j < this.width / pitch; ++j) {
        ctx.fillStyle = this.color[i]; 
        ctx.fillRect(j * pitch, this.height + 20 * i - 150, 36, 20);
        ctx.fillStyle = this.color[0];
        ctx.fillText('s'.concat(i.toString()), 10 + j * pitch, this.height - 134 + 20 * i);        
      }        
    }         
  }

  ngOnInit() {
    this.render();
  }
}
