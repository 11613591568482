import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared/shared.module';
import { DisplayComponent } from './display.component';
import { DeviceComponent } from './device.component';
import { FrameComponent } from './frame.component';
import { CommandComponent } from './command.component';
import { CanvasComponent } from './canvas.component';
import { ConceptComponent } from './concept.component';
import { SchedulingComponent } from './scheduling.component';
import { HyperSchedulingComponent } from './hyper.scheduling.component';
import { MapComponent } from './map.component';
import { StatusComponent } from './status.component';
import { RefugeeComponent } from './refugee.component';
import { StatusBarComponent } from './status.bar.component';
import { SchedulingGraphComponent } from './scheduling.graph.component';
import { RSCSGraphComponent } from './rscsgraph/rscsgraph.component';
import { RSCSStatusComponent } from './rscsstatus/rscsstatus.component';
import { RSCSCommandComponent } from './rscscommand/rscscommand.component';

const displayRoutes: Routes = [
  {
    path: 'display',
    component: DisplayComponent
  },
  {
    path: 'display/hyperScheduling',
    component: HyperSchedulingComponent
  },
  {
    path: 'refugee',
    component: RefugeeComponent
  }  
];

@NgModule({
  declarations: [
    DisplayComponent, 
    DeviceComponent,
    FrameComponent,
    CommandComponent,
    CanvasComponent,
    ConceptComponent,
    SchedulingComponent,
    HyperSchedulingComponent,
    MapComponent,
    StatusComponent,
    RefugeeComponent,
    StatusBarComponent,
    SchedulingGraphComponent,
    RSCSGraphComponent,
    RSCSStatusComponent,
    RSCSCommandComponent,
    //RemoveBranchComponent
    //ToolbarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forChild(displayRoutes),
    BrowserAnimationsModule
  ],
  bootstrap: [
    DisplayComponent
  ],
  exports: [
    DisplayComponent      
  ],
})

export class DisplayModule {}