import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-map',  
  templateUrl: './map.component.html',
  styleUrls: ['../shared/global.css']
})

export class MapComponent {
  lat_: number = -27.0; 
  lng_: number = 153.0; 
  @Input() set lat(value: number) {
    this.lat_ = value;
    this.refresh();
  };
  @Input() set lng(value: number) {
    this.lng_ = value;
    this.refresh();
  }; 
  @Input() show: Boolean = false; 
  zoom: number = 13;  
  width: number = 800; 
  height: number = 400;
  apiKey: string = "AIzaSyD77cDdjV59EsHjru1RzsVCdrDLWubGBv8";  
  googleMapStaticURL: string; 
 
  zoomIn() {
    if(++this.zoom > 21) this.zoom = 21;
    this.refresh();
  }

  zoomOut() {
    if(--this.zoom < 8) this.zoom = 8;
    this.refresh();
  }

  refresh() {    
    this.width = screen.availWidth > 800 ? 800 : (screen.availWidth * 1);
    this.height = this.width / 2;
    this.googleMapStaticURL = "http://maps.googleapis.com/maps/api/staticmap?center=" + this.lat_ +
                              "," + this.lng_ + "&zoom=" + this.zoom + "&maptype=roadmap&size=" + this.width + "x" + this.height +                              
                              "&markers=color:red%%7Clabel:S%%7C" + this.lat_ + "," + this.lng_ + "&sensor=false&key=" + this.apiKey;    
  }
}