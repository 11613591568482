import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { Cache } from '../shared/cache.service'

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css', '../shared/global.css']
})

export class BranchComponent implements OnInit, OnDestroy {
  //branch: FormControl = new FormControl(null, Validators.required);
  branch_old: FormControl = new FormControl();
  branch_new: FormControl = new FormControl(null, Validators.required);
  company: FormControl = new FormControl(null, Validators.required);
  usersNo: FormControl = new FormControl(null, Validators.required);
  //addBranchGroup: FormGroup;
  editBranchGroup: FormGroup;
  private branchListChangeSubscription_: Subscription;
  private companyChangeSubscription_: Subscription;
  private taskChangeSubscription_: Subscription;
  private userChangeSubscription_: Subscription;
  user: any;
  companies: any;
  branches : any;
  signs: any;
  showAddFlag: Boolean = false;
  showEditFlag: Boolean = false;
  showDeleteFlag: Boolean = false;
  editIndex: any = -1;  
  branchFilterModel: any = '';  
  companyFilterModel: any = '';
  
  constructor(@Inject(FormBuilder) formBuilder:FormBuilder, 
      private http: HttpClient, 
      private router: Router,
      private cache: Cache) {

    this.editBranchGroup = formBuilder.group({  
      branch_old: this.branch_old,     
      branch_new: this.branch_new,
      company_in: this.company,
      users_no: this.usersNo               
    });

    this.branchListChangeSubscription_ = cache.branchListChange.subscribe(
      branch => {          
      this.branches = branch;    
    });

    this.companyChangeSubscription_ = cache.companyListChange.subscribe(
      company => {          
      this.companies = company;    
    });

    this.taskChangeSubscription_ = cache.taskChange.subscribe(
      task => {
        if(task == 'edit') { 
          this.showEditFlag = !this.showEditFlag;
          this.showDeleteFlag = false;
          this.editIndex = -1;   
          this.resetFilter();
        }        
    });

    this.userChangeSubscription_ = cache.userChange.subscribe(user => { 
      this.user = user;      
    });
    this.cancel();
  }

  showAddForm() {
    this.showAddFlag = !this.showAddFlag;
    this.editIndex = -1;     
    /*this.addBranchGroup.patchValue({
      company_in: '', 
      branch_in: '', 
      users_no: '8'});*/
  }


  cancel() {
    this.showAddFlag = false;   
    this.showEditFlag = false;   
    this.showDeleteFlag = false;
    this.editIndex = -1;  
    this.resetFilter();
  }

  showEditForm(i) {    
    this.editIndex = i;    
    this.showEditFlag = false;
    this.showAddFlag = false;
    this.usersNo.setValue(this.branches[i].uno)
    this.editBranchGroup.patchValue({
        company_in: this.branches[i].cnm, 
        branch_old: this.branches[i].bnm, 
        branch_new: this.branches[i].bnm, 
        usersNo: this.usersNo});
  }

  editForm(i) {
    if(this.editBranchGroup.valid) {
      this.http.post('/branch/edit', this.editBranchGroup.value).subscribe(
        branch => { 
          this.cache.setBranchList(branch); 
          this.cancel(); 
          (<any>window).ga('send', 'event', {
            eventCategory: 'Branch Edit',
            eventLabel: this.user.email,
            eventAction: this.branch_new.value,
            eventValue: 2
          });
        });        
    }
  }

  remove(i) {        
    if(this.showDeleteFlag) {      
      var branch_ = this.branches[i].bnm;
      this.http.post('/branch/remove', {branch_in: branch_, company_in: this.branches[i].cnm}).subscribe(
        branch => { 
          this.cache.setBranchList(branch); 
          this.cancel(); 
          (<any>window).ga('send', 'event', {
            eventCategory: 'Branch Remove',
            eventLabel: this.user.email,
            eventAction: branch_,
            eventValue: 2
          });
        });
    } else {
      this.showDeleteFlag = true;
      this.editIndex = i;
    };
  }

  selectBranch(i) {
    this.cache.setBranch({name: this.branches[i].bnm, users_no: this.branches[i].uno});
    this.router.navigate(['display']);
  }

  resetFilter() {
    this.branchFilterModel = '';
    this.companyFilterModel = '';
  }

  filter(i) {
    var flag = true;
    if(this.companyFilterModel != '' && !this.branches[i].cnm.toLowerCase().startsWith(this.companyFilterModel.toLowerCase())) flag = false
    if(this.branchFilterModel != '' && !this.branches[i].bnm.toLowerCase().startsWith(this.branchFilterModel.toLowerCase())) flag = false
    return flag;
  }

  ngOnInit() {
    if(!this.branches) {
      this.http.post('/branch/list', {}).subscribe(branch => {
        this.cache.setBranchList(branch);
      });
    } /*else {      
      if(this.branches.length == 1 && 
        this.user.access != 'web_admin' &&
        this.user.access != 'service_admin' &&
        this.user.access != 'service_tech') {
        this.cache.setBranch({name: this.branches[0].bnm, users_no: this.branches[0].uno});
        this.router.navigate(['display']);
      }      
    }*/

    if(!this.companies) {      
      this.http.get('/company/list').subscribe(company => {
        this.cache.setCompanyList(company);  
      });
    }
  }

  ngOnDestroy() {     
    this.companyChangeSubscription_.unsubscribe();  
    this.branchListChangeSubscription_.unsubscribe(); 
    this.taskChangeSubscription_.unsubscribe();
    this.userChangeSubscription_.unsubscribe();
  }
}
